import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import API from "../../../utils/apiCalling";
import "./toptradewarrants.css";
import { config } from "../../../utils/apiUrl";
import moment from "moment";
import thLocale from 'moment/locale/th'
import enLocale from "moment/locale/en-in"
import LoaderSpin from "../../../components/common/LoaderSpin";
import { useContext } from "react";
import LangContext from "../../../components/common/context/lang-context";
import { languageText } from "../../../languageText/topTradedDw"
import LiveMatrixSideBars from "../../../components/common/Sidebar/LiveMatrixSideBar"
import WarrantSearchSideBars from "../../../components/common/Sidebar/WarrantSearchSideBar"
import WarrantCalculatorSideBars from "../../../components/common/Sidebar/WarrantCalculatorSideBar"
import AdvertiseMentSidebar from "../../../components/common/Sidebar/AdvertiseMentSidebar"


const Toptradewarrants = (props) => {
  const langContext = useContext(LangContext);
  const api = new API();
  const [tradedWarrant, setTradedWarrant] = useState([]);
  const [tradedWarrantMap, setTradedWarrantMap] = useState([]);
  const [tradedWarrantMap2, setTradedWarrantMap2] = useState([]);

  const [tabButton1, setTabButton1] = useState("active");
  const [tabButton2, setTabButton2] = useState("");
  const [tabButton3, setTabButton3] = useState("");
  const [tabButton4, setTabButton4] = useState("");
  const [tabButton5, setTabButton5] = useState("");
  const [tabButton6, setTabButton6] = useState("");
  const [tabButton7, setTabButton7] = useState("");
  const [tabButton8, setTabButton8] = useState("");
  const [tabButton9, setTabButton9] = useState("");
  const [tabButton10, setTabButton10] = useState("");
  const [tabButton11, setTabButton11] = useState("");

  const [tabButtonGainer, setTabButtonGainer] = useState("active");
  const [tabButtonLoser, setTabButtonLoser] = useState("");

  const [firstCheckBox, setFirstCheckBox] = useState(true);
  const [secondCheckBox, setSecondCheckBox] = useState(true);

  const [displayBlock, setDisplayBlock] = useState("block");
  const [displayBlock1, setDisplayBlock1] = useState("none");

  const [isLoading, setIsLoading] = useState(true);
  const [siteLang , setSiteLang] = useState("")
  

  const getTradedWarrant = async () => {
    setIsLoading(true);
    let fastTrack = await api.get(config.marketTurnOver, true, {
      type: "ranking",
      qid: Date.now(),
    });
    if (fastTrack) {
      setIsLoading(false);
      setTradedWarrant(fastTrack);
      setTradedWarrantMap(fastTrack.topindicesMqOnly);
      setTradedWarrantMap2(fastTrack.gainerMqOnly);
    }
  };

  useEffect(() => {
    getTradedWarrant();
  }, []);

  const [language, setLanguage] = useState("thai");

  useEffect(() => {
    window.scrollTo(0, 0);
    var siteLanguage = $.cookie('applang');
    setSiteLang(siteLanguage)
    if(siteLanguage == "eng"){
      document.querySelector("body").classList.add("eng")
    }else{
      document.querySelector("body").classList.remove("eng")
    }
  }, []);

  const btnFunction1 = () => {
    setTabButton1("active");
    setTabButton2("");
    setTabButton3("");
    setTabButton4("");
    setTabButton5("");
    setTabButton6("");
    setTabButton7("");
    setTabButton8("");
    setTabButton9("");
    setTabButton10("");
    setTabButton11("");
    setDisplayBlock("block");
    setDisplayBlock1("none");
    if (firstCheckBox === true) {
      setTradedWarrantMap(tradedWarrant.topindicesMqOnly);
    } else {
      setTradedWarrantMap(tradedWarrant.topindices);
    }
  };

  const btnFunction2 = () => {
    setTabButton1("");
    setTabButton2("active");
    setTabButton3("");
    setTabButton4("");
    setTabButton5("");
    setTabButton6("");
    setTabButton7("");
    setTabButton8("");
    setTabButton9("");
    setTabButton10("");
    setTabButton11("");
    setDisplayBlock("block");
    setDisplayBlock1("none");
    if (firstCheckBox === true) {
      setTradedWarrantMap(tradedWarrant.tophstechMqOnly);
    } else {
      setTradedWarrantMap([]);
    }
  };

  const btnFunction10 = () => {
    setTabButton1("");
    setTabButton2("");
    setTabButton3("");
    setTabButton4("");
    setTabButton5("");
    setTabButton6("");
    setTabButton7("");
    setTabButton8("");
    setTabButton9("");
    setTabButton11("");
    setTabButton10("active");
    setDisplayBlock("block");
    setDisplayBlock1("none");
    if (firstCheckBox === true) {
      setTradedWarrantMap(tradedWarrant.tophsceiMqOnly);
    } else {
      setTradedWarrantMap(tradedWarrant.tophscei);
    }
  };

  const btnFunction3 = () => {
    setTabButton1("");
    setTabButton2("");
    setTabButton3("active");
    setTabButton4("");
    setTabButton5("");
    setTabButton6("");
    setTabButton7("");
    setTabButton8("");
    setTabButton9("");
    setTabButton10("");
    setTabButton11("");
    setDisplayBlock("block");
    setDisplayBlock1("none");
    if (firstCheckBox === true) {
      setTradedWarrantMap(tradedWarrant.topsp500MqOnly);
    } else {
      setTradedWarrantMap(tradedWarrant.topsp500);
    }
  };

  const btnFunction4 = () => {
    setTabButton1("");
    setTabButton2("");
    setTabButton3("");
    setTabButton4("active");
    setTabButton5("");
    setTabButton6("");
    setTabButton7("");
    setTabButton8("");
    setTabButton9("");
    setTabButton10("");
    setTabButton11("");
    setDisplayBlock("block");
    setDisplayBlock1("none");
    if (firstCheckBox === true) {
      setTradedWarrantMap(tradedWarrant.tophsiMqOnly);
    } else {
      setTradedWarrantMap(tradedWarrant.tophsi);
    }
  };

  const btnFunction5 = () => {
    setTabButton1("");
    setTabButton2("");
    setTabButton3("");
    setTabButton4("");
    setTabButton5("active");
    setTabButton6("");
    setTabButton7("");
    setTabButton8("");
    setTabButton9("");
    setTabButton10("");
    setTabButton11("");
    setDisplayBlock("block");
    setDisplayBlock1("none");
    if (firstCheckBox === true) {
      setTradedWarrantMap(tradedWarrant.topdwMqOnly);
    } else {
      setTradedWarrantMap(tradedWarrant.topdw);
    }
  };

  const btnFunction6 = () => {
    setTabButton1("");
    setTabButton2("");
    setTabButton3("");
    setTabButton4("");
    setTabButton5("");
    setTabButton6("active");
    setTabButton7("");
    setTabButton8("");
    setTabButton9("");
    setTabButton10("");
    setTabButton11("");
    setDisplayBlock("block");
    setDisplayBlock1("none");
    if (firstCheckBox === true) {
      setTradedWarrantMap(tradedWarrant.tophkMqOnly);
    } else {
      setTradedWarrantMap(tradedWarrant.tophk);
    }
  };

  const btnFunction7 = () => {
    setTabButton1("");
    setTabButton2("");
    setTabButton3("");
    setTabButton4("");
    setTabButton5("");
    setTabButton6("");
    setTabButton7("active");
    setTabButton8("");
    setTabButton9("");
    setTabButton10("");
    setTabButton11("");
    setDisplayBlock("none");
    setDisplayBlock1("block");
    setTradedWarrantMap(tradedWarrant.new28);
  };

  const btnFunction8 = () => {
    setTabButton1("");
    setTabButton2("");
    setTabButton3("");
    setTabButton4("");
    setTabButton5("");
    setTabButton6("");
    setTabButton7("");
    setTabButton8("active");
    setTabButton9("");
    setTabButton10("");
    setTabButton11("");
    setDisplayBlock("block");
    setDisplayBlock1("none");
    if (firstCheckBox === true) {
      setTradedWarrantMap(tradedWarrant.topdjiMqOnly);
    } else {
      setTradedWarrantMap(tradedWarrant.topdji);
    }
  };

  const btnFunction11 = () => {
    setTabButton1("");
    setTabButton2("");
    setTabButton3("");
    setTabButton4("");
    setTabButton5("");
    setTabButton6("");
    setTabButton7("");
    setTabButton8("");
    setTabButton9("");
    setTabButton10("");
    setTabButton11("active");
    setDisplayBlock("block");
    setDisplayBlock1("none");
    if (firstCheckBox === true) {
      setTradedWarrantMap(tradedWarrant.topn225MqOnly);
    } else {
      setTradedWarrantMap(tradedWarrant.topn225);
    }
  };
  const btnFunction9 = () => {
    setTabButton1("");
    setTabButton2("");
    setTabButton3("");
    setTabButton4("");
    setTabButton5("");
    setTabButton6("");
    setTabButton7("");
    setTabButton8("");
    setTabButton9("active");
    setTabButton10("");
    setTabButton11("");
    setDisplayBlock("block");
    setDisplayBlock1("none");
    if (firstCheckBox === true) {
      setTradedWarrantMap(tradedWarrant.topndxMqOnly);
    } else {
      setTradedWarrantMap(tradedWarrant.topndx);
    }
  };

  const firstCheckFunction = () => {
    var fc = "";
    if (firstCheckBox === true) {
      setFirstCheckBox(false);
      fc = false;
    } else if (firstCheckBox === false) {
      setFirstCheckBox(true);
      fc = true;
    }
    setTableData(fc);
  };

  const setTableData = (fc) => {
    if (fc === true && tabButton1 == "active") {
      setTradedWarrantMap(tradedWarrant.topindicesMqOnly);
    } else if (fc === false && tabButton1 == "active") {
      setTradedWarrantMap(tradedWarrant.topindices);
    }
    if (fc === true && tabButton2 == "active") {
      setTradedWarrantMap(tradedWarrant.tophstechMqOnly);
    } else if (fc === false && tabButton2 == "active") {
      setTradedWarrantMap([]);
    } else if (fc === true && tabButton3 == "active") {
      setTradedWarrantMap(tradedWarrant.topsp500MqOnly);
    } else if (fc === false && tabButton3 == "active") {
      setTradedWarrantMap(tradedWarrant.topsp500);
    } else if (fc === true && tabButton4 == "active") {
      setTradedWarrantMap(tradedWarrant.tophsiMqOnly);
    } else if (fc === false && tabButton4 == "active") {
      setTradedWarrantMap(tradedWarrant.tophsi);
    } else if (fc === true && tabButton5 == "active") {
      setTradedWarrantMap(tradedWarrant.topdwMqOnly);
    } else if (fc === false && tabButton5 == "active") {
      setTradedWarrantMap(tradedWarrant.topdw);
    } else if (fc === true && tabButton6 == "active") {
      setTradedWarrantMap(tradedWarrant.tophkMqOnly);
    } else if (fc === false && tabButton6 == "active") {
      setTradedWarrantMap(tradedWarrant.tophk);
    } else if (fc === true && tabButton7 == "active") {
      setTradedWarrantMap(tradedWarrant.new28);
    } else if (fc === false && tabButton7 == "active") {
      setTradedWarrantMap(tradedWarrant.new28);
    } else if (fc === true && tabButton8 == "active") {
      setTradedWarrantMap(tradedWarrant.topdjiMqOnly);
    } else if (fc === false && tabButton8 == "active") {
      setTradedWarrantMap(tradedWarrant.topdji);
    } else if (fc === true && tabButton9 == "active") {
      setTradedWarrantMap(tradedWarrant.topndxMqOnly);
    } else if (fc === false && tabButton9 == "active") {
      setTradedWarrantMap(tradedWarrant.topndx);
    }else if(fc === true && tabButton11 == "active") {
      setTradedWarrantMap(tradedWarrant.topn225MqOnly);
    } else if (fc === false && tabButton11 == "active") {
      setTradedWarrantMap(tradedWarrant.topn225);
    }
  };

  const btnFunctionGainer = () => {
    setTabButtonGainer("active");
    setTabButtonLoser("");
    if (secondCheckBox === true) {
      setTradedWarrantMap2(tradedWarrant.gainerMqOnly);
    } else {
      setTradedWarrantMap2(tradedWarrant.gainer);
    }
  };

  const btnFunctionLoser = () => {
    setTabButtonGainer("");
    setTabButtonLoser("active");
    if (secondCheckBox === true) {
      setTradedWarrantMap2(tradedWarrant.loserMqOnly);
    } else {
      setTradedWarrantMap2(tradedWarrant.loser);
    }
  };

  const secondCheckFunction = () => {
    var sc = "";
    if (secondCheckBox === true) {
      setSecondCheckBox(false);
      var sc = false;
    } else if (secondCheckBox === false) {
      setSecondCheckBox(true);
      var sc = true;
    }
    setTableData1(sc);
  };

  const setTableData1 = (sc) => {
    if (sc === true && tabButtonGainer == "active") {
      setTradedWarrantMap2(tradedWarrant.gainerMqOnly);
    } else if (sc === false && tabButtonGainer == "active") {
      setTradedWarrantMap2(tradedWarrant.gainer);
    }
    if (sc === true && tabButtonLoser == "active") {
      setTradedWarrantMap2(tradedWarrant.loserMqOnly);
    } else if (sc === false && tabButtonLoser == "active") {
      setTradedWarrantMap2(tradedWarrant.loser);
    }
  };

  return (
    <div id="toptradewarrant" className="pageobj">
      <div className="breadcrumbbar">
        <div className="container-fluid">
          <ol className="breadcrumb">
            <li>
              <Link to="/home?dwstat">
                {siteLang == "eng" ? languageText.firstNavigationEn : languageText.firstNavigation}
              </Link>
            </li>
            <li className="active">
              {siteLang == "eng" ? languageText.secondNavigationEn : languageText.secondNavigation}
            </li>
          </ol>
        </div>
      </div>
      <div className="stripe-bg" />
      <div className="container-fluid">
        <div className="topimg hidden-sm hidden-xs">
          <img src="/img/top-toptradeddw.jpg" alt="indexdw" />
        </div>
        <div className="row">
          <div className="col-md-8">
            <div className="section">
              <div className="page-header">
                <h1>{siteLang == "eng" ? languageText.pageTitleEn : languageText.pageTitle}</h1>
              </div>
              <p>{siteLang == "eng" ? languageText.pageDescriptionEn : languageText.pageDescription}</p>
              <div className="tablist">
                <div id="topTabs" className="tablist-left">
                  <table>
                    <tbody>
                      <tr>
                        <td className={tabButton1} onClick={btnFunction1}>
                          <div className="tablist-item">
                            <div className="tablist-item-txt">SET50 DW </div>
                          </div>
                        </td>
                        <td className={tabButton2} onClick={btnFunction2}>
                          <div className="tablist-item">
                            <div className="tablist-item-txt">HSTECH DW</div>
                          </div>
                        </td>
                        <td className={tabButton10} onClick={btnFunction10}>
                          <div className="tablist-item">
                            <div className="tablist-item-txt">HSCEI DW</div>
                          </div>
                        </td>
                        <td className={tabButton3} onClick={btnFunction3}>
                          <div className="tablist-item">
                            <div className="tablist-item-txt">
                              S&amp;P 500 DW
                            </div>
                          </div>
                        </td>
                        <td className={tabButton4} onClick={btnFunction4}>
                          <div className="tablist-item">
                            <div className="tablist-item-txt">HSI DW</div>
                          </div>
                        </td>
                        <td className={tabButton5} onClick={btnFunction5}>
                          <div className="tablist-item">
                            <div className="tablist-item-txt">
                              Single Stock DW
                            </div>
                          </div>
                        </td>
                        <td className={tabButton6} onClick={btnFunction6}>
                          <div className="tablist-item">
                            <div className="tablist-item-txt">HK Stock DW</div>
                          </div>
                        </td>
                        <td className={tabButton7} onClick={btnFunction7}>
                          <div className="tablist-item">
                            <div className="tablist-item-txt">New DW 28</div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td className={tabButton8} onClick={btnFunction8}>
                          <div className="tablist-item">
                            <div className="tablist-item-txt">DJI DW</div>
                          </div>
                        </td>
                        <td className={tabButton9} onClick={btnFunction9}>
                          <div className="tablist-item">
                            <div className="tablist-item-txt">NDX DW</div>
                          </div>
                        </td>
                        <td className={tabButton11} onClick={btnFunction11}>
                          <div className="tablist-item">
                            <div className="tablist-item-txt">NIKKEI 225</div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="tablist-right hidden-xs hidden-sm">
                  <div className="lastupdatebox hidden-xs hidden-sm">
                    Last update :{" "}
                    <span>{siteLang == "eng" ? moment(new Date()).locale('en', [enLocale]).format('DD MMM YY') : moment(new Date()).locale('th', [thLocale]).format('DD MMM YY')}</span>
                  </div>
                </div>
              </div>
              <div id="topTbl">
                <div className="table_div" style={{ display: displayBlock }}>
                  <table className="table table-striped table-closed">
                    <thead>
                      <tr className="rowtmpl_h">
                        <th>
                          {siteLang == "eng" ? languageText.tableSymbolEn : languageText.tableSymbol}
                        </th>
                        <th>
                          {siteLang == "eng" ? languageText.tableUnderlyingEn : languageText.tableUnderlying}
                        </th>
                        <th className="hidden-xs">
                          {siteLang == "eng" ? languageText.tableIssuerEn : languageText.tableIssuer}
                        </th>
                        <th>
                          {siteLang == "eng" ? languageText.tableTypeEn : languageText.tableType}
                        </th>
                        <th>
                          {siteLang == "eng" ? languageText.tableBidEn : languageText.tableBid}
                        </th>
                        <th>
                          {siteLang == "eng" ? languageText.tableChangeEn : languageText.tableChange}
                        </th>
                        <th className="hidden-xs">
                          {siteLang == "eng" ? languageText.tableTurnoverEn : languageText.tableTurnover}
                        </th>
                        <th className="hidden-xs">
                          {siteLang == "eng" ? languageText.tableVolumeEn : languageText.tableVolume}
                        </th>
                        <th className="visible-xs">
                          {siteLang == "eng" ? "Volume (mil)" : "ปริมาณการซื้อขาย (mil)"}
                        </th>
                      </tr>
                    </thead>
                    {isLoading && (
                      <tbody>
                        <tr style={{ backgroundColor: "#fff" }}>
                          <td colSpan="8">
                            <div className="loader-div">
                              <LoaderSpin isLoading={isLoading} isVisible />
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    )}
                    <tbody>
                      {tradedWarrantMap?.length > 0 ? (
                        tradedWarrantMap.map((data, index) => (
                          <tr className="rowtmpl" key={index}>
                            <td className="col ticker" style={{ fontWeight: "bold" }}>
                              <Link to={`/tools/livematrix/${data?.ticker}`}>
                                {data?.ticker}
                              </Link>
                            </td>
                            <td className="col u_ticker">{data?.u_ticker}</td>
                            <td className="col issuer hidden-xs">{data?.issuer}</td>
                            <td className="col type hidden-xs">{data?.type}</td>
                            <td className="col type visible-xs">{data?.type == "CALL" ? "C" : "P"}</td>
                            <td className="col bid">{data?.bid}</td>
                            <td className={data?.pct_dis > 0 ? "col pct_dis up" : data?.pct_dis < 0 ? "col pct_dis down" : "col pct_dis"}>
                              {data?.pct_dis > 0 ? "+" + data?.pct_dis : data?.pct_dis}
                            </td>
                            <td className="col turnover hidden-xs">{data?.turnover}</td>
                            <td className="col vol hidden-xs">{data?.vol}</td>
                            <td className="col vol visible-xs">{((Number(data?.vol?.replaceAll(",", ""))/1000).toFixed(1)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                          </tr>
                        ))
                      ) : (
                        <tr className={isLoading === true ? "none emptymsg" : "emptymsg"}>
                          <td colSpan="8">No data</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
                <div className="table_div table_dw28" style={{ display: displayBlock1 }}>
                  <table className="table table-striped table-closed">
                    <thead>
                      <tr className="rowtmpl_hdw28">
                        <th>
                          {siteLang == "eng" ? languageText.tableSymbolEn : languageText.tableSymbol}
                        </th>
                        <th>
                          {siteLang == "eng" ? languageText.tableTypeEn : languageText.tableType}
                        </th>
                        <th>
                          {siteLang == "eng" ? languageText.tableStrikeEn : languageText.tableStrike}
                        </th>
                        <th>
                          {siteLang == "eng" ? languageText.tableRatioEn : languageText.tableRatio}
                        </th>
                        <th>
                          {siteLang == "eng" ? languageText.tableFirstTradeEn : languageText.tableFirstTrade}
                        </th>
                        <th>
                          {siteLang == "eng" ? languageText.tableLastTradeEn : languageText.tableLastTrade}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {tradedWarrantMap?.length > 0 ? (
                        tradedWarrantMap.map((data, index) => (
                          <tr className="rowtmpl_dw28" key={index}>
                            <td className="col ticker">
                              <Link to={`/tools/livematrix/${data?.symbol}`}>
                                {data?.symbol}
                              </Link>
                            </td>
                            <td className="col type">{data?.type}</td>
                            <td className="col strike">{data?.strike}</td>
                            <td className="col ratio">{data?.ratio}</td>
                            <td className="col first_date">{data?.first_date}</td>
                            <td className="col last_date">{data?.last_date}</td>
                          </tr>
                        ))
                      ) : (
                        <tr className={isLoading === true ? "none emptymsg" : "emptymsg"}>
                          <td colSpan="8">No data</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>

                <div className="checkbox-mqonly">
                  <input
                    type="checkbox"
                    checked={firstCheckBox}
                    onChange={firstCheckFunction}
                  />
                  <label>
                    {siteLang == "eng" ? languageText.macquarieOnlyEn : languageText.macquarieOnly}
                  </label>
                </div>
                <p className="small">
                  {siteLang == "eng" ? languageText.aboveTableEn : languageText.aboveTable}
                  <br />
                  <br />
                  {siteLang == "eng" ? languageText.marketDelayEn : languageText.marketDelay}
                </p>
              </div>
            </div>
            <div className="section">
              <div id="glTabs" className="tablist">
                <div className="tablist-left">
                  <table>
                    <tbody>
                      <tr>
                        <td
                          className={tabButtonGainer}
                          onClick={btnFunctionGainer}
                        >
                          <div className="tablist-item">
                            <div className="tablist-item-txt">{siteLang == "eng" ? languageText.topGainerEn : languageText.topGainer}</div>
                          </div>
                        </td>
                        <td
                          className={tabButtonLoser}
                          onClick={btnFunctionLoser}
                        >
                          <div className="tablist-item">
                            <div className="tablist-item-txt">{siteLang == "eng" ? languageText.topLoserEn : languageText.topLoser}</div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="tablist-right hidden-xs hidden-sm">
                  <div className="lastupdatebox hidden-xs hidden-sm">
                    Last update :{" "}
                    <span>{siteLang == "eng" ? moment(new Date()).locale('en', [enLocale]).format('DD MMM YY') : moment(new Date()).locale('th', [thLocale]).format('DD MMM YY')}</span>
                  </div>
                </div>
              </div>
              <div id="glTbl">
                <table className="table table-striped table-closed">
                  <thead>
                    <tr>
                      <th>
                        {siteLang == "eng" ? languageText.tableSymbolEn : languageText.tableSymbol}
                      </th>
                      <th>
                        {siteLang == "eng" ? languageText.tableUnderlyingEn : languageText.tableUnderlying}
                      </th>
                      <th className="hidden-xs">
                        {siteLang == "eng" ? languageText.tableIssuerEn : languageText.tableIssuer}
                      </th>
                      <th>
                        {siteLang == "eng" ? languageText.tableTypeEn : languageText.tableType}
                      </th>
                      <th>
                        {siteLang == "eng" ? languageText.tableBidEn : languageText.tableBid}
                      </th>
                      <th>
                        {siteLang == "eng" ? languageText.tableChangeEn : languageText.tableChange}
                      </th>
                      <th className="hidden-xs">
                        {siteLang == "eng" ? languageText.tableTurnoverEn : languageText.tableTurnover}
                      </th>
                      <th className="hidden-xs">
                        {siteLang == "eng" ? languageText.tableVolumeEn : languageText.tableVolume}
                      </th>
                      <th className="visible-xs">
                        {siteLang == "eng" ? "Volume (mil)" : "ปริมาณการซื้อขาย (mil)"}
                      </th>
                    </tr>
                  </thead>
                  {isLoading && (
                    <tbody>
                      <tr style={{ backgroundColor: "#fff" }}>
                        <td colSpan="8">
                          <div className="loader-div">
                            <LoaderSpin isLoading={isLoading} isVisible />
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  )}
                  <tbody>
                    {tradedWarrantMap2?.length > 0 ? (
                      tradedWarrantMap2.map((data, index) => (
                        <tr className="rowtmpl" key={index}>
                          <td className="col ticker" style={{ fontWeight: "bold" }}>
                            <Link to={`/tools/livematrix/${data?.ticker}`}>
                              {data?.ticker}
                            </Link>
                          </td>
                          <td className="col u_ticker">{data?.u_ticker}</td>
                          <td className="col issuer hidden-xs">{data?.issuer}</td>
                          <td className="col type">{data?.type == "CALL" ? "C" : "P"}</td>
                          <td className="col bid">{data?.bid}</td>
                          <td className={data?.pct_dis > 0 ? "col pct_dis up" : data?.pct_dis < 0 ? "col pct_dis down" : "col pct_dis"}>
                            {data?.pct_dis > 0 ? "+" + data?.pct_dis : data?.pct_dis}
                          </td>
                          <td className="col turnover hidden-xs">{data?.turnover}</td>
                          <td className="col vol hidden-xs">{data?.vol}</td>
                          <td className="col vol visible-xs">{((Number(data?.vol.replaceAll(",", ""))/1000).toFixed(1)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                        </tr>
                      ))
                    ) : (
                      <tr className={isLoading === true ? "none emptymsg" : "emptymsg"}>
                        <td colSpan="8">No data</td>
                      </tr>
                    )}
                  </tbody>
                </table>
                <div className="checkbox-mqonly">
                  <input
                    type="checkbox"
                    onChange={secondCheckFunction}
                    checked={secondCheckBox}
                  />
                  <label>
                    {siteLang == "eng" ? languageText.macquarieOnlyEn : languageText.macquarieOnly}
                  </label>
                </div>
                <p className="small">
                  {siteLang == "eng" ? languageText.aboveTableEn : languageText.aboveTable}
                  <br />
                  <br />
                  {siteLang == "eng" ? languageText.marketDelayEn : languageText.marketDelay}
                </p>
              </div>
            </div>
          </div>
          <div className="hidden-xs hidden-sm col-md-4">            
            <LiveMatrixSideBars />
            <WarrantSearchSideBars />
            {/* <WarrantCalculatorSideBars /> */}
            <AdvertiseMentSidebar />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Toptradewarrants;
