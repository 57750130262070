import React, { useEffect, useState } from "react";
import LangContext from "../../../components/common/context/lang-context";
import { useContext } from "react";
import { Link, useHistory } from "react-router-dom";
import { config } from "../../../utils/apiUrl";
import API from "../../../utils/apiCalling";
import ReactTooltip from "react-tooltip";
import { languageText } from "../../../languageText/home";
import moment from "moment";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Select from "react-select";
import OutsideClickHandler from 'react-outside-click-handler';
import AdvertiseMentSidebar from "../../../components/common/Sidebar/AdvertiseMentSidebar"

const WarrantTool = () => {
  const langContext = useContext(LangContext);
  const api = new API();
  const history = useHistory()
  const [hsidata, setHsidata] = useState();
  const [htidata, setHtidata] = useState();
  const [hhidata, setHhidata] = useState();
  const [updateDate, setUpdateDate] = useState("");
  const [dw28, setDw28] = useState([])
  const [dwTips, setDwTips] = useState([])
  const [expiryDates, setExpiryDates] = useState([])
  const [hotlist, setHotlist] = useState([])
  const [soldout, setSoldout] = useState([])
  const [matrixSymbols, setMatrixSymbols] = useState([])
  const [matrixTicker, setMatrixTicker] = useState("")
  const [tipsImg, setTipsImg] = useState([])
  const [topdwMqOnly, setTopdwMqOnly] = useState([])
  const [topmover, setTopmover] = useState([])
  const [trendingWarrants, setTrendingWarrants] = useState([])
  const [underlyings, setUnderlyings] = useState([])
  const [lastViewVal, setLastViewVal] = useState([])
  const [dailyHsiBanner, setDailyHsiBanner] = useState()
  const [dailyHighlightBanner, setDailyHighlightBanner] = useState()
  const [dailySingleBanner, setDailySingleBanner] = useState()
  const [trendingWarrantData, setTrendingWarrantData] = useState()
  const [popUpClose, setPopUpClose] = useState(false)
  const [popUpImg, setPopUpImg] = useState("")
  const [siteLang, setSiteLang] = useState("")
  const [prevNetChng, setPrevNetChng] = useState("")
  const hsi_point = 25, sp500_point = 2.5, hstech_point = 10, dji_point = 20, ndx_point = 10, set_point = 0.5, hk_point = 0.5;

  useEffect(() => {
    var siteLanguage = $.cookie('applang');
    setSiteLang(siteLanguage)
    if (siteLanguage == "eng") {
      document.querySelector("body").classList.add("eng")
    } else {
      document.querySelector("body").classList.remove("eng")
    }
    getHomeLiveIndexHSI();
    getHomeLiveIndexHTI();
    getHomeLiveIndexHHI();
    getNewsBanner();
    getHomeJson();
  }, []);

  const settings = {
    autoplay: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: true,
  };

  const colourStyles = {
    control: (provided) => ({
      ...provided,
      display:"flex",
      alignItems:"center",
      justifyContent:"center",
      textAlign: 'center', // Center-align the text input
    }),
    input:(provided)=>({
      ...provided,
      width:"100%",
      display:"flex "
    }),
    singleValue: (provided) => ({
      ...provided,
      textAlign: 'center', // Center-align the selected value
    }),
    option: (provided, state) => {
      return {
        backgroundColor: state.isFocused ? "#007fff !important" : null,
        color: "#333",
        color: state.isFocused ? "#ffffff" : null,
        fontSize: siteLang == "eng" ? "14px" : "21px",
        textAlign: "center",
        padding: "3px 14px 3px 5.6px",
        cursor: "pointer",
        borderTop: state.isFocused ? "1px solid #003eff" : null,
        borderBottom: state.isFocused ? "1px solid #003eff" : null,
      };
    },
  };

  const getHomeLiveIndexHSI = async () => {
    let LiveIndexHSIResult = await api.get(config.livenewsdata1, true, {
      ric: "HSI",
    });
    setHsidata(LiveIndexHSIResult);
    let date = new Date(LiveIndexHSIResult.stime);
    let hours = date.getHours();
    let min = date.getMinutes();
    let today = new Date();
    let s = today.getSeconds();
    if (s < 10) {
      s = "0" + s;
    }
    hours = hours - 1;
    var ampm = "AM";
    if (hours > 11) {
      if (hours != 12) hours = hours - 12;
      ampm = "PM";
    }
    if (hours < 10) {
      hours = "0" + hours;
    }
    if (min < 10) {
      min = "0" + min;
    }
    setUpdateDate(hours + ":" + min + ":" + s + " " + ampm);
  };

  const getHomeLiveIndexHTI = async () => {
    let LiveIndexHTIResult = await api.get(config.livenewsdata1, true, {
      ric: "HTI",
    });
    setHtidata(LiveIndexHTIResult);
  };
  useEffect(()=>{
    var a = window.setInterval(function(){
      if(document.getElementById('indexfuture')){
        getHomeLiveIndexHHI()
      }else{
        clearInterval(a)
      }
    }, 10000
    ); 
  },[])
  const getHomeLiveIndexHHI = async () => {
    let LiveIndexHHIResult = await api.get(config.livenewsdata2, true);
    setHhidata(LiveIndexHHIResult[LiveIndexHHIResult.keys[0]][0]);
    setPrevNetChng(Number(LiveIndexHHIResult[LiveIndexHHIResult.keys[0]][0].net))
  };

  const refreshdata = () => {
    getHomeLiveIndexHSI()
    getHomeLiveIndexHTI()
    getHomeLiveIndexHHI()
  }

  const getHomeJson = async () => {
    var ric = $.cookie('TOOLS_PREF_RIC_TH');
    var lang = $.cookie('applang');
    var security_code = (ric == null) ? "" : ric.replace(".BK", "");
    let homeJsonResult = await api.get(
      config.homeJSON,
      true,
      { m: "dwtool", qid: Date.now() }
    );
    if (homeJsonResult != "" || homeJsonResult != undefined || homeJsonResult != null || homeJsonResult != []) {
      if (homeJsonResult && homeJsonResult?.topdwMqOnly && homeJsonResult?.topdwMqOnly?.length > 0) {
        ric = homeJsonResult?.topdwMqOnly[0]?.ric;
        $.cookie('TOOLS_PREF_RIC_TH', ric, { expires: 365, path: '/' });
        security_code = homeJsonResult?.topdwMqOnly[0]?.ticker;
      }
      setDw28(homeJsonResult.dw28)
      setDwTips(homeJsonResult.dwTips)
      if (homeJsonResult && homeJsonResult?.symbols) {
        setMatrixSymbols(homeJsonResult.symbols)
      }
      var tipsImgData = homeJsonResult.tipsImg
      if (tipsImgData?.length > 0) {
        getTipsImgData(tipsImgData)
      }

      if ((homeJsonResult?.topmover?.length > 0 || homeJsonResult?.topmover != undefined) || (homeJsonResult?.trending_warrants?.length > 0 || homeJsonResult?.trending_warrants != undefined)) {
        setTopmover(homeJsonResult.topmover)
        var trending_warrants = homeJsonResult.trending_warrants
        setTrendingWarrantData(homeJsonResult.trending_warrants)
        var topmover = homeJsonResult.topmover

        for (var i = 0; i < topmover?.US_index?.length; i++) {
          var data = topmover.US_index[i];
          var item = document.querySelectorAll(".usindex .index-item-" + i)
          item.forEach((e) => {
            e.querySelector(".hotlist-item-dataarea").setAttribute("name", data.underlying_name);
          })
          item.forEach((e) => {
            e.querySelector(".hotlist-item-dataarea").setAttribute("delay", "1");
          })
          var symbol = "";
          symbol = data.underlying_ticker;
          item.forEach((e) => {
            e.querySelector(".hotlist-item-nm").innerHTML = symbol
          })
          item.forEach((e) => {
            e.querySelector(".hotlist-item-TRDPRC_1").innerHTML = data.TRDPRC_1
          })
          item.forEach((e) => {
            e.querySelector(".hotlist-item-nc").innerHTML = (data.NETCHNG_1 >= 0) ? "+" + data.NETCHNG_1 : "-" + Math.abs(data.NETCHNG_1)
          })
          if (data.PCTCHNG == null) {
            data.PCTCHNG = "-";
          }
          item.forEach((e) => {
            e.querySelector(".hotlist-item-pc").innerHTML = (data.PCTCHNG > 0) ? "+" + data.PCTCHNG + "%" : data.PCTCHNG + "%"
          })
          item.forEach((e) => {
            e.querySelector(".vabtn").setAttribute("href", "/tools/dwsearch?underlying=" + encodeURIComponent(data.underlying_ticker));
          })
          if (lang == "eng") {
            item.forEach((e) => {
              e.querySelector(".vabtn").innerHTML = "View all warrants"
            })
          }
          else {
            if (symbol == "S&P500") {
              item.forEach((e) => {
                e.querySelector(".vabtn").innerHTML = "ค้นหา SPX DW ทั้งหมด"
              })
            } else {
              item.forEach((e) => {
                e.querySelector(".vabtn").innerHTML = "ค้นหา " + symbol + " DW ทั้งหมด"
              })
            }
          }
          var updown = "up";
          if (data.NETCHNG_1 >= 0) {
            updown = "up";
          } else {
            updown = "down";
          }
          item.forEach((e) => {
            e.querySelector(".hotlist-item-trend").classList.add(updown);
          })
          item.forEach((e) => {
            e.querySelector(".hotlist-item-pc").classList.add(updown);
          })
          item.forEach((e) => {
            e.querySelector(".hotlist-item-nc").classList.add(updown);
          })
        }
        if (topmover?.US_index?.length >= 1) {
          for (var i = 3; i >= topmover?.US_index?.length; i--) {
            var item = document.querySelectorAll(".usindex .index-item-" + i)
            item.forEach((e) => {
              e.style.display = "none"
            })
          }
        }

        for (var i = 0; i < topmover?.HK_index?.length; i++) {
          var data = topmover.HK_index[i];
          var item = document.querySelectorAll(".hkindex .index-item-" + i)
          item.forEach((e) => {
            e.querySelector(".hotlist-item-dataarea").setAttribute("name", data.underlying_name)
          })
          var symbol = "";
          symbol = data.underlying_ticker;
          item.forEach((e) => {
            e.querySelector(".hotlist-item-nm").innerHTML = symbol
          })
          item.forEach((e) => {
            e.querySelector(".hotlist-item-TRDPRC_1").innerHTML = data.TRDPRC_1
          })
          item.forEach((e) => {
            e.querySelector(".hotlist-item-nc").innerHTML = (data.NETCHNG_1 >= 0) ? "+" + data.NETCHNG_1 : "-" + Math.abs(data.NETCHNG_1)
          })
          if (data.PCTCHNG === null) {
            item.forEach((e) => {
              e.querySelector(".hotlist-item-pc").innerHTML = "-%"
            })
          } else {
            item.forEach((e) => {
              e.querySelector(".hotlist-item-pc").innerHTML = (data.PCTCHNG > 0) ? "+" + data.PCTCHNG + "%" : data.PCTCHNG + "%"
            })
          }
          item.forEach((e) => {
            e.querySelector(".vabtn").setAttribute("href", "/tools/dwsearch?underlying=" + encodeURIComponent(data.underlying_ticker));
          })
          if (lang == "eng") {
            item.forEach((e) => {
              e.querySelector(".vabtn").innerHTML = "View all warrants"
            })
          }
          else {
            item.forEach((e) => {
              e.querySelector(".vabtn").innerHTML = "ค้นหา " + symbol + " DW ทั้งหมด"
            })
          }
          var updown = "up";
          if (data.NETCHNG_1 >= 0) {
            updown = "up";
          } else {
            updown = "down";
          }
          item.forEach((e) => {
            e.querySelector(".hotlist-item-trend").classList.add(updown);
          })
          item.forEach((e) => {
            e.querySelector(".hotlist-item-pc").classList.add(updown);
          })
          item.forEach((e) => {
            e.querySelector(".hotlist-item-nc").classList.add(updown);
          })
        }
        if (topmover?.HK_index?.length >= 1) {
          for (var i = 3; i >= topmover?.HK_index?.length; i--) {
            var item = document.querySelectorAll(".hkindex .index-item-" + i)
            item.forEach((e) => {
              e.style.display = "none"
            })
          }
        }

        for (var i = 0; i < topmover?.HK_stocks?.length; i++) {
          var data = topmover.HK_stocks[i];
          var item = document.querySelectorAll(".hkstock .index-item-" + i)
          if (i == 0) {
            item.forEach((e) => {
              e.classList.add("active");
            })
            document.querySelectorAll(".tw-name").forEach((e) => {
              e.innerHTML = data.underlying_name
            })
            trendingWarrantFun(trending_warrants, data.underlying_name);
          }
          item.forEach((e) => {
            e.querySelector(".hotlist-item-dataarea").setAttribute("name", data.underlying_name);
          })
          var symbol = "";
          if (data.underlying_name.indexOf("-") >= 0) {
            symbol = data.underlying_name.substring(0, data.underlying_name.indexOf("-"));
          } else {
            symbol = data.underlying_name;
          }
          item.forEach((e) => {
            e.querySelector(".hotlist-item-nm").innerHTML = symbol
          })
          item.forEach((e) => {
            e.querySelector(".hotlist-item-dataarea").setAttribute("delay", "1");
          })
          item.forEach((e) => {
            e.querySelector(".hotlist-item-TRDPRC_1").innerHTML = "HK$" + data.TRDPRC_1
          })
          item.forEach((e) => {
            e.querySelector(".hotlist-item-nc").innerHTML = (data.NETCHNG_1 >= 0) ? "+$" + data.NETCHNG_1 : "-$" + Math.abs(data.NETCHNG_1)
          })
          item.forEach((e) => {
            e.querySelector(".hotlist-item-pc").innerHTML = (data.PCTCHNG > 0) ? "+" + data.PCTCHNG + "%" : data.PCTCHNG + "%"
          })
          item.forEach((e) => {
            e.querySelector(".vabtn").setAttribute("href", "/tools/dwsearch?underlying=" + encodeURIComponent(data.underlying_ticker));
          })
          var updown = "up";
          if (data.NETCHNG_1 >= 0) {
            updown = "up";
          } else {
            updown = "down";
          }
          item.forEach((e) => {
            e.querySelector(".hotlist-item-trend").classList.add(updown);
          })
          item.forEach((e) => {
            e.querySelector(".hotlist-item-pc").classList.add(updown);
          })
          item.forEach((e) => {
            e.querySelector(".hotlist-item-nc").classList.add(updown);
          })
        }
        if (topmover?.HK_stocks?.length >= 1) {
          for (var i = 3; i >= topmover?.HK_stocks?.length; i--) {
            var item = ".hkstock .index-item-" + i
            item.style.display = "none"
          }
        }

      }

      setLivematrix(ric, security_code, 1);
      var lvValue = $.cookie("LAST_VIEWS_TH");
      var lvList = (lvValue === null) ? [] : lvValue.split(",");
      if ($.inArray(ric, lvList) >= 0)
        lvList.splice($.inArray(ric, lvList), 1);
      lvList.unshift(ric);
      lvList.slice(0, lvList.length > 10 ? 10 : lvList.length);
      let lastViewData = await api.get(
        config.lastView,
        true,
        {
          type: "lastview",
          ric: JSON.parse(window.localStorage.getItem("ric"))
            ? JSON.parse(window.localStorage.getItem("ric")).slice(0, 6).toString()
            : ""
        }
      );
      setLastViewVal(lastViewData.lvList)
    }
  };

  const hotlistItemDataArea = (data) => {
    var mainItem = event.target.parentElement
    var name = event.target.parentElement.getAttribute("name")
    var uname = mainItem.querySelector(".hotlist-item-nm").innerHTML;
    var item = mainItem.parentElement
    if (item.getAttribute("class").indexOf("hks") < 0) {
      if (siteLang == "thai" || siteLang == undefined || siteLang == null || siteLang == "") {
        document.querySelector(".sensitivity_text").innerHTML = "Sensitivity (จุดดัชนี):"
      } else {
        document.querySelector(".sensitivity_text").innerHTML = "Sensitivity by index point:"
      }
    } else {
      document.querySelector(".sensitivity_text").innerHTML = "Sensitivity:"
    }
    var img = item.getAttribute("class").slice(0, 3);
    var val = item.getAttribute("class").substr(-1);
    for (let i = 0; 3 >= i; i++) {
      var items = ".tw_area_" + i
      if (val == i) {
        document.querySelector(items).style.display = "block"
        document.querySelectorAll("#topmover img").forEach((e) => {
          e.style.display = "none"
        })
        if (img == "usi") {
          document.querySelector(".usindex_" + i + " img").style.display = "block"
        } else if (img == "hki") {
          document.querySelector(".hkindex_" + i + " img").style.display = "block"
        } else {
          document.querySelector(".hkstock_" + i + " img").style.display = "block"
        }
      } else {
        document.querySelector(items).style.display = "none"
      }
    }
    trendingWarrantFun(trendingWarrantData, name);
    if (data == "mobile") {
      item.parentElement.parentElement.parentElement.parentElement.parentElement.querySelector(".tw-name").innerHTML = uname
    } else {
      item.parentElement.parentElement.nextElementSibling.querySelector(".tw-name").innerHTML = uname
    }
    document.querySelectorAll(".hotlist-item").forEach((e) => {
      e.classList.remove("active")
    })
    if (data == "mobile") {
      item.classList.add("active");
    } else {
      item.classList.add("active");
    }
  }

  function trendingWarrantFun(trending_warrants, underlying_name) {
    if (!trending_warrants)
      return;
    var data = trending_warrants[underlying_name];
    var title_name = "";
    if (underlying_name.indexOf("-") >= 0) {
      title_name = underlying_name.substring(0, underlying_name.indexOf("-"));
    } else {
      title_name = underlying_name;
    }
    if (data) {
      document.querySelectorAll(".notw").forEach((e) => {
        e.style.display = "none"
      })
      document.querySelectorAll(".call_table").forEach((e) => {
        e.style.display = "block"
      })
      document.querySelectorAll(".put_table").forEach((e) => {
        e.style.display = "block"
      })
      var call_data = data.CALL;
      var put_data = data.PUT;
      var call_html = "";
      if (call_data && call_data.length > 0) {
        call_data.forEach((callElement) => {
          call_html += `<tr class="call_tmp">
              <td>
              <div class="tw_warrant">
                  <div colSpan="2" class="tw_title">
                  <div class="tw_type tw_ticker">${callElement?.ticker}</div>                                                           
                  </div>
                  <table style="width: 100%">
                  <tbody>
                      <tr>
                      <td style="width: 40%">
                          Bid:
                      </td>
                      <td class="tw_strike pdl_10" style="width: 60%">${callElement?.BID}</td>
                      </tr>
                      <tr>
                      <td style="width: 40%; font-size: 18px">
                          Price change: 
                      </td>
                      <td style="width: 60%">
                          <span class="${callElement?.BID_PCTCHNG > 0 ? "up" : "down"} tw_BID_PCTCHNG pdl_10">${callElement?.BID_PCTCHNG !== null ? (callElement?.BID_PCTCHNG > 0 ? "+" + callElement?.BID_PCTCHNG : callElement?.BID_PCTCHNG) : "-"}%</span>                   
                      </td>
                      </tr>
                      <tr>
                      <td style="width: 40%">
                          Eff.gearing:
                      </td>
                      <td class="tw_eff pdl_10" style="width: 60%">${callElement?.effective_gearing !== null ? callElement?.effective_gearing : "-"}</td>
                      </tr>
                      <tr>
                      <td class="sensitivity_text" style="width: 40%">Sensitivity:</td>
                      <td class="tw_expiry pdl_10" style="width: 60%">${(callElement?.sensitivity == "-" || callElement?.sensitivity == "N/A" || callElement?.sensitivity == null || callElement?.sensitivity == undefined) ? "-" : (1.0 / Number(callElement?.sensitivity)).toFixed(2)}</td>
                      </tr>
                  </tbody>
                  </table>                                                
              </div>
              </td>
          </tr>`;
        })
      }
      document.querySelectorAll(".tw_area").forEach((element) => {
        if (element.style.display != "none") {
          element.querySelector(".tw_call_table tbody").innerHTML = call_html
        }
      })
      var put_html = "";
      if (put_data && put_data.length > 0) {
        put_data.forEach((putElement) => {
          put_html += `<tr class="call_tmp">
          <td>
          <div class="tw_warrant">
              <div colSpan="2" class="tw_title">
              <div class="tw_type tw_ticker">${putElement?.ticker}</div>                                                           
              </div>
              <table style="width: 100%">
              <tbody>
                  <tr>
                  <td style="width: 40%">
                      Bid:
                  </td>
                  <td class="tw_strike pdl_10" style="width: 60%">${putElement?.BID}</td>
                  </tr>
                  <tr>
                  <td style="width: 40%; font-size: 18px">
                      Price change: 
                  </td>
                  <td style="width: 60%">
                      <span class="${putElement?.BID_PCTCHNG > 0 ? "up" : "down"} tw_BID_PCTCHNG pdl_10">${putElement?.BID_PCTCHNG !== null ? (putElement?.BID_PCTCHNG > 0 ? "+" + putElement?.BID_PCTCHNG : putElement?.BID_PCTCHNG) : "-"}%</span>                   
                  </td>
                  </tr>
                  <tr>
                  <td style="width: 40%">
                      Eff.gearing:
                  </td>
                  <td class="tw_eff pdl_10" style="width: 60%">${putElement?.effective_gearing !== null ? putElement?.effective_gearing : "-"}</td>
                  </tr>
                  <tr>
                  <td class="sensitivity_text" style="width: 40%">Sensitivity:</td>
                  <td class="tw_expiry pdl_10" style="width: 60%">${(putElement?.sensitivity == "-" || putElement?.sensitivity == "N/A" || putElement?.sensitivity == null || putElement?.sensitivity == undefined) ? "-" : (1.0 / Number(putElement?.sensitivity)).toFixed(2)}</td>
                  </tr>
              </tbody>
              </table>                                                
          </div>
          </td>
      </tr>`;
        })
      }
      document.querySelectorAll(".tw_area").forEach((element) => {
        if (element.style.display != "none") {
          element.querySelector(".tw_put_table tbody").innerHTML = put_html
        }
      })
    }
    else {
      document.querySelectorAll(".notw").forEach((e) => {
        e.style.display = "block"
      })
      document.querySelectorAll(".call_table").forEach((e) => {
        e.style.display = "none"
      })
      document.querySelectorAll(".put_table").forEach((e) => {
        e.style.display = "none"
      })
    }
  }

  const hottabusiFunc = () => {
    document.querySelectorAll('.hottab').forEach((e) => {
      e.classList.remove("active")
    })
    document.querySelector('.hottab.hottabusi').classList.add("active");
    document.querySelectorAll('.ulist').forEach((e) => {
      e.classList.remove("active")
    })
    document.querySelector('.ulist.usilist').classList.add("active");
  }

  const hottabhkiFunc = () => {
    document.querySelectorAll('.hottab').forEach((e) => {
      e.classList.remove("active")
    })
    document.querySelector('.hottab.hottabhki').classList.add("active");
    document.querySelectorAll('.ulist').forEach((e) => {
      e.classList.remove("active")
    })
    document.querySelector('.ulist.hkilist').classList.add("active");
  }

  const hottabhksFunc = () => {
    document.querySelectorAll('.hottab').forEach((e) => {
      e.classList.remove("active")
    })
    document.querySelector('.hottab.hottabhks').classList.add("active");
    document.querySelectorAll('.ulist').forEach((e) => {
      e.classList.remove("active")
    })
    document.querySelector('.ulist.hkslist').classList.add("active");
  }
  const _check_hsi_compare_table = (compare_table, value) => {
    if (compare_table && value) {
      for (var i = 0; i < compare_table.length; i++) {
        if (value <= compare_table[i].from && value >= compare_table[i].to) {
          return compare_table[i].bid;
        }
      }
    }
    return 0;
  }
  const setLivematrix = async (ric, security_code, frist) => {
    let matrixJsonResult = await api.get(
      config.LiveMatrix,
      true,
      { ric: ric, mode: 0, home: frist }
    );
    if (matrixJsonResult) {
      var ric_data = matrixJsonResult && matrixJsonResult?.ric_data && matrixJsonResult?.ric_data;
      if (matrixJsonResult?.ric_data.length > 0) {
        ric_data = matrixJsonResult && matrixJsonResult?.ric_data && matrixJsonResult?.ric_data[0]
      }
      $.cookie('TOOLS_PREF_RIC_TH', ric_data?.ric, { expires: 365, path: '/' });
      var tableElement = document.querySelector("#lmatrix #today_table tbody");

      var livematrix = matrixJsonResult.livematrix;
      var ubidask = (ric_data?.type == 'PUT') ? "underlying_ask" : "underlying_bid";
      setMatrixTicker(ric_data?.ticker);
      // if (livematrix && livematrix.length > 4) {
      //   var midpoint, tablecontent="";
      //   tableElement.innerHTML = "";

      //   midpoint = findMidPoint(livematrix, ric_data?.lmuprice, false, false, ric_data?.type);
      //   console.log(midpoint)
      //   var livematrixindex = 0;
      //   var livematrixlimit = 9;
      //   livematrixindex = midpoint.index;
      //   if (livematrixindex == 0) {
      //     livematrixindex = Math.ceil(livematrix.length / 2);
      //   }
      //   var startIndex, endIndex;
      //   if (midpoint.index == livematrix.length - 1) {
      //     startIndex = midpoint.index - 3;
      //     endIndex = midpoint.index;
      //   } else if (midpoint.index < 2) {
      //     startIndex = 0;
      //     endIndex = 3;
      //   } else {
      //     startIndex = midpoint.index - 2;
      //     endIndex = midpoint.index + 1;
      //   }
      //   for (var i = startIndex; i < endIndex + 1; i++) {
      //     var livematrixdata = livematrix[i];
      //     var isMidPoint = (livematrixdata[ubidask] == midpoint.price) ? true : false;
      //     tablecontent += isMidPoint ? "<tr class='midpoint'><td>" : "<tr><td>";
      //     tablecontent += Number(livematrixdata.underlying_bid).toFixed(2);
      //     tablecontent += "</td><td class='right-b01'>";
      //     tablecontent += Number(livematrixdata.bid).toFixed(2);
      //     tablecontent += "</td></tr>";
      //   }
      //   tableElement.innerHTML = tablecontent;
      //   var rows = tableElement.querySelectorAll('tr:not([style*="display:none"])');
      //   for (var i = 0; i < rows.length; i++) {
      //     if (i % 2 === 0) {
      //       rows[i].classList.add('bgcolor-08');
      //     }
      //   }
      //   // tableElement
      //   // .querySelector("tr:visible")
      //   // .filter(":even")
      //   // .addClass("bgcolor-08");
      // }
      if (livematrix && livematrix.length > 4) {
        var midpoint, tablecontent="";
        tableElement.innerHTML = "";
          var use_point = hsi_point;
          if (ric_data.underlying_ric.toString().endsWith(".HK")) {
            use_point = hk_point;
          }
          var first_point, last_point;
          var compare_table = [];
          if (ric_data.type == "CALL") {
            first_point = livematrix[0].underlying_bid - livematrix[0].underlying_bid % use_point;
            last_point = livematrix[livematrix.length - 1].underlying_bid - livematrix[livematrix.length - 1].underlying_bid % use_point + use_point;
  
            for (var i = 0; i < livematrix.length - 1; i += 2) {
              var compare_data = {
                "from": livematrix[i].underlying_bid,
                "to": livematrix[i + 1].underlying_bid,
                "bid": livematrix[i].bid
              };
              compare_table.push(compare_data);
            }
          } else {
            first_point = livematrix[0].underlying_ask - livematrix[0].underlying_ask % use_point;
            last_point = livematrix[livematrix.length - 1].underlying_ask - livematrix[livematrix.length - 1].underlying_ask % use_point + use_point;
            for (var i = 0; i < livematrix.length - 1; i += 2) {
              var compare_data = {
                "from": livematrix[i].underlying_ask,
                "to": livematrix[i + 1].underlying_ask,
                "bid": livematrix[i].bid
              };
              compare_table.push(compare_data);
            }
          }
          midpoint = findMidPoint(livematrix, ric_data?.lmuprice, false, false, ric_data?.type);
          var classrow = 0;
          for (var i = first_point; i >= last_point; i -= use_point) {
            if (classrow == 0) {
              if (first_point >= Number(midpoint.price) <= last_point) {
                if (i.toString().substring(0, 4) == midpoint.price.toString().substring(0, 4)) {
                  classrow = i;
                  break;
                } else if (i.toString().substring(0, 3) == midpoint.price.toString().substring(0, 3)) {
                  if (Number(i) - Number(midpoint.price) < 25) {
                    var bid = Math.abs(i + 25 - Number(midpoint.price));
                    var bid1 = Math.abs(i - Number(midpoint.price));
                    var bid2 = Math.abs((i - 25) - Number(midpoint.price));
  
                    if (bid < bid1 && bid < bid2) {
                      classrow = i + 25;
                      break;
                    } else if (bid1 < bid && bid1 < bid2) {
                      classrow = i;
                      break;
                    } else if (bid2 < bid && bid2 < bid1) {
                      classrow = i - 25;
                      break;
                    }
                  }
                }
              }
            } else {
              break;
            }
          }
          var tableEl = []
          for (var i = last_point; i < first_point; i += use_point) {
            var bid = _check_hsi_compare_table(compare_table, i);
            tableEl.push({unBid:i,dwBid:bid})
          }
          var index = findClosestNumber(tableEl,matrixJsonResult.ric_data.underlying_bid_price)
        var startIndex, endIndex;
          if (index == tableEl.length - 1) {
            startIndex = index - 4;
            endIndex = index;
          } else if (index < 2) {
            startIndex = 0;
            endIndex = 4;
          } else {
            startIndex = index - 2;
            endIndex = index + 2;
        }
        for (var i = startIndex; i < endIndex + 1; i++) {
            var isMidPoint = (tableEl[i]['unBid'] == classrow) ? true : false;
            tablecontent += isMidPoint ? "<tr class='midpoint '><td>" : "<tr><td>";
            tablecontent += tableEl[i]['unBid'].toFixed(2);
            tablecontent += "</td><td class='right-b01'>";
            tablecontent += tableEl[i]['dwBid'];
            tablecontent += "</td></tr>";
        }
        tableElement.innerHTML = tablecontent;
      }
      
      var rows = tableElement.querySelectorAll('tr:not([style*="display:none"])');
      for (var i = 0; i < rows.length; i++) {
        if (i % 2 === 0) {
          rows[i].classList.add('bgcolor-08');
        }
      }
    }
  }
  const  findClosestNumber = (arr, target) =>{
    let closest = arr[0].unBid;
    let difference = Math.abs(closest - target);
    let index = 0;
    for (let num in arr) {
      let currentDifference = Math.abs(arr[num].unBid - target);
      if (currentDifference < difference) {
        index = num;
        difference = currentDifference;
      }
    }
    return index;
  }
  const findMidPoint = (lmdata, lmprice, iscompress, isfiveday, type) => {
    var mp = {
      price: -1,
      diff: -1,
      index: -1,
    };
    for (var i = 0; i < lmdata?.length; i++) {
      if (iscompress && !(i % 2 == 0)) {
        continue;
      }
      var ubid;
      if (isfiveday && !iscompress) {
        ubid = Object.keys(lmdata[i])[0];
      } else {
        ubid = lmdata[i][type == "PUT" ? "underlying_ask" : "underlying_bid"];
      }
      var diff = lmprice ? Math.abs(lmprice.replace(/,/g, "") - ubid) : -1;
      if (mp.index === -1 || mp.diff > diff) {
        mp.diff = diff;
        mp.price = ubid;
        mp.index = i;
      }
    }
    return mp;
  };

  const getTipsImgData = async (tipsImgData) => {
    var items = []
    let advImgDataVal = await api.get(config.advImgData, true, {
      tipsbanner: tipsImgData[0].img_id,
    });
    items.push({
      img_id: advImgDataVal,
      img_link: tipsImgData[0].img_link,
    });
    setTipsImg(items);
  }

  const getNewsBanner = async () => {
    let bannerResult = await api.get(config.homeNewsBanner, true);
    var dailyHsi = bannerResult.daily_hsi
    var dailyHighlight = bannerResult.daily_highlight
    var dailySingleStock = bannerResult.daily_single_stock
    if (dailyHsi?.length > 0) {
      let advImgData = await api.get(config.advImgData, true, {
        hsi: dailyHsi[0].id,
      });
      setDailyHsiBanner({
        img: advImgData,
        id: dailyHsi[0].id,
      });
    }
    if (dailyHighlight?.length > 0) {
      let advImgData = await api.get(config.advImgData, true, {
        dailyid: dailyHighlight[0].id,
      });
      setDailyHighlightBanner({
        img: advImgData,
        id: dailyHighlight[0].id,
      });
    }
    if (dailySingleStock?.length > 0) {
      let advImgData = await api.get(config.advImgData, true, {
        singlestock: dailySingleStock[0].id,
      });
      setDailySingleBanner({
        img: advImgData,
        id: dailySingleStock[0].id,
      });
    }
  };

  const redirectToLiveMatrix = (data) => {
    history.push("/tools/livematrix/" + data.value)
  }

  const openModalPopup = async (id) => {
    setPopUpImg("")
    setPopUpClose(true)
    document.querySelector("body").classList.add("noScroll")
    let advImgData = await api.get(config.advImgData, true, {
      dw: id,
    });
    setPopUpImg(advImgData)
  }

  const closeModalPopup = () => {
    setPopUpClose(false)
    document.querySelector("body").classList.remove("noScroll")
  }

  return (
    <div>
      <div className="tab-container container-fluid">
        <div id="dwtool" className="tabcontent">
          <div className="frame-lgmd">
            <div className="row">
              <div className="col-md-6 col-sm-6 col-left">

                <div className="holder-lmatrix hidden-xs">
                  <div id="lmatrix" className="section">
                    <div className="page-header">
                      <h1 className="small">{siteLang == "eng" ? languageText.liveMatrixTitleEn : languageText.liveMatrixTitle}</h1>
                    </div>
                    <p className="section-p">
                      {siteLang == "eng" ? languageText.liveMatrixParaEn : languageText.liveMatrixPara}
                    </p>
                    <div id="dropdown-livematrix">
                      <Select
                        onChange={(e) => redirectToLiveMatrix(e)}
                        placeholder={siteLang == "eng" ? "Select DW symbol" : "เลือกชื่อย่อ DW"}
                        className="Warrent_dropdown select_align_center"
                        options={matrixSymbols && matrixSymbols.map((d) => {
                          return { value: d.security_code, label: d.security_code }
                        })}
                        styles={colourStyles}
                      />
                    </div>
                    <div id="ticker" className="ricbar bgcolor-03">
                      {matrixTicker}
                    </div>
                    <div id="lmtablecontainer">
                      <table id="today_table" className="table lbtable">
                        <thead>
                          <tr>
                            <th width="50%">
                              <span className="th-left">
                                {siteLang == "eng" ? languageText.matrixTableUnderlyingEn : languageText.matrixTableUnderlying}
                              </span>
                            </th>
                            <th width="50%">
                              <a
                                style={{ display: "none" }}
                                className="icon-refresh color-hyperlink pull-right"
                              />
                              {siteLang == "eng" ? languageText.matrixTableDwEn : languageText.matrixTableDw}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {/* <tr className="bgcolor-08">
                            <td>19566.00</td>
                            <td className="right-b01">1.40</td>
                          </tr> */}
                          <tr>
                            <td colSpan="6" className="text-content">
                              {siteLang == "eng" ? languageText.noLiveMatrixEn : languageText.noLiveMatrix}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div className="section-btn-wapper">
                      <Link
                        to={"/tools/livematrix/"+matrixTicker}
                        style={{ minWidth: "190px" }}
                        type="button"
                        className="btn btn-primary"
                      >
                        {siteLang == "eng" ? languageText.matrixTableButtonEn : languageText.matrixTableButton}
                      </Link>
                    </div>
                  </div>
                </div>

                <div className="holder-qlink-dwsearch visible-xs">
                  <div id="qlink-dwsearch" className="section" style={{ backgroundColor: "#000" }}>
                    <div className="page-header">
                      <h1 className="small" style={{ color: "#FECC0A" }}>
                        {siteLang == "eng" ? languageText.dwSearchTitleEn : languageText.dwSearchTitle}
                      </h1>
                    </div>
                    <div className="row">
                      <div className="col-md-6 col-md-12">
                        <Link to="/tools/dwsearch/Allforeignindices">
                          {siteLang == "eng" ? (
                            <img
                              className="search_btn"
                              src="/img/home/search_en1.png"
                            />
                          ) : (
                            <img
                              className="search_btn"
                              src="/img/home/search_th1.png"
                            />
                          )}
                        </Link>
                      </div>
                      <div className="col-md-6 col-md-12">
                        <Link to="/tools/dwsearch/HKindex">
                          {siteLang == "eng" ? (
                            <img
                              className="search_btn"
                              src="/img/home/search_en2.png"
                            />
                          ) : (
                            <img
                              className="search_btn"
                              src="/img/home/search_th2.png"
                            />
                          )}
                        </Link>
                      </div>
                      <div className="col-md-6 col-md-12">
                        <Link to="/tools/dwsearch/HKSS">
                          {siteLang == "eng" ? (
                            <img
                              className="search_btn"
                              src="/img/home/search_en3.png"
                            />
                          ) : (
                            <img
                              className="search_btn"
                              src="/img/home/search_th3.png"
                            />
                          )}
                        </Link>
                      </div>
                      <div className="col-md-6 col-md-12">
                        <Link to="/tools/dwsearch/USindex">
                          {siteLang == "eng" ? (
                            <img
                              className="search_btn"
                              src="/img/home/search_en4.png"
                            />
                          ) : (
                            <img
                              className="search_btn"
                              src="/img/home/search_th4.png"
                            />
                          )}
                        </Link>
                      </div>
                      <div className="col-md-6 col-md-12">
                        <Link to="/tools/dwsearch/ThaiSS">
                          {siteLang == "eng" ? (
                            <img
                              className="search_btn"
                              src="/img/home/search_en5.png"
                            />
                          ) : (
                            <img
                              className="search_btn"
                              src="/img/home/search_th5.png"
                            />
                          )}
                        </Link>
                      </div>
                      <div className="col-md-6 col-md-12">
                        <Link to="/tools/dwsearch/Thaiindex">
                          {siteLang == "eng" ? (
                            <img
                              className="search_btn"
                              src="/img/home/search_en6.png"
                            />
                          ) : (
                            <img
                              className="search_btn"
                              src="/img/home/search_th6.png"
                            />
                          )}
                        </Link>
                      </div>
                    </div>
                    <div className="section-btn-wapper">
                      <Link to="/tools/dwsearch" type="button" className="btn btn-primary">
                        {siteLang == "eng" ? languageText.dwSearchBtnEn : languageText.dwSearchBtn}
                      </Link>
                    </div>
                  </div>
                </div>

                <div className="holder-indexfuture visible-xs">
                  <div id="indexfuture" className="section">
                    <div className="page-header">
                      <h1 className="small">
                        {siteLang == "eng" ? languageText.liveIndexTitleEn : languageText.liveIndexTitle}
                      </h1>
                    </div>
                    <table className="table table-striped table-noHeaderborder">
                      <thead>
                        <tr>
                          <th>{siteLang == "eng" ? languageText.futureTableIndexEn : languageText.futureTableIndex}</th>
                          <th>{siteLang == "eng" ? languageText.futureTableContractEn : languageText.futureTableContract} </th>
                          <th>{siteLang == "eng" ? languageText.futureTableLastEn : languageText.futureTableLast}</th>
                          <th>{siteLang == "eng" ? languageText.futureTableChangeEn : languageText.futureTableChange} </th>
                          <th>{siteLang == "eng" ? languageText.futureTableChangePerEn : languageText.futureTableChangePer}%</th>
                          <th />
                        </tr>
                      </thead>
                      <tbody>
                        {hsidata && <tr className="row_HSI">
                          <td>
                            <Link to="/tools/dwranking/HSI">HSI*</Link>
                          </td>
                          <td className="col_month">{hsidata?.month}</td>
                          <td className="col_last">{new Intl.NumberFormat('en-US').format(hsidata?.last)}</td>
                          <td className={Number(hsidata?.chng) > 0 ? "col_chng val up" : Number(hsidata?.chng) < 0 ? "col_chng val down" : "col_chng val"}>
                            {Number(hsidata?.chng) > 0 ? "+" + hsidata?.chng : hsidata?.chng}
                          </td>
                          <td className={hsidata?.pchng != 0 ? (Number(hsidata?.pchng?.replace("%", "")) > 0 ? "col_pchng val up" : Number(hsidata?.pchng?.replace("%", "")) < 0 ? "col_pchng val down" : "col_pchng val") : (Number(hsidata?.pchng) > 0 ? "col_pchng val up" : Number(hsidata?.pchng) < 0 ? "col_pchng val down" : "col_pchng val")}>
                            {hsidata?.pchng != 0 ? (Number(hsidata?.pchng?.replace("%", "")) > 0 ? '+' + hsidata?.pchng?.replace("%", "") : hsidata?.pchng?.replace("%", "")) : (Number(hsidata?.pchng) > 0 ? '+' + hsidata?.pchng : hsidata?.pchng)}
                          </td>
                          <td rowSpan={2} style={{ verticalAlign: 'middle' }}>
                            <img onClick={refreshdata} className="refresh_index" style={{ cursor: 'pointer', width: '22px' }} src="/img/bullet/refresh.png" alt="refresh" />
                          </td>
                        </tr>}
                        {htidata && <tr className="row_HTI">
                          <td>
                            <Link to="/tools/dwranking/HSTECH">HSTECH*</Link>
                          </td>
                          <td className="col_month">{htidata?.month}</td>
                          <td className="col_last">{new Intl.NumberFormat('en-US').format(htidata?.last)}</td>
                          <td className={Number(htidata?.chng) > 0 ? "col_chng val up" : Number(htidata?.chng) < 0 ? "col_chng val down" : "col_chng val"}>
                            {Number(htidata?.chng) > 0 ? "+" + htidata?.chng : htidata?.chng}
                          </td>
                          <td className={htidata?.pchng != 0 ? (Number(htidata?.pchng?.replace("%", "")) > 0 ? "col_pchng val up" : Number(htidata?.pchng?.replace("%", "")) < 0 ? "col_pchng val down" : "col_pchng val") : (Number(htidata?.pchng) > 0 ? "col_pchng val up" : Number(htidata?.pchng) < 0 ? "col_pchng val down" : "col_pchng val")}>
                            {htidata?.pchng != 0 ? (Number(htidata?.pchng?.replace("%", "")) > 0 ? '+' + htidata?.pchng?.replace("%", "") : htidata?.pchng?.replace("%", "")) : (Number(htidata?.pchng) > 0 ? '+' + htidata?.pchng : htidata?.pchng)}
                          </td>
                        </tr>}
                        {/* {hhidata && <tr className={`row_HHI`}>
                          <td>
                            <Link to="/tools/dwranking/HSCEI">NIKKEI 225</Link>
                          </td>
                          <td className="col_month">{hhidata?.expiryMonth}</td>
                          <td className="col_last">{hhidata?.bid}</td>
                          <td className={Number(hhidata?.net) > 0 ? "col_chng val up" : Number(hhidata?.net) < 0 ? "col_chng val down" : "col_chng val"}>
                            {hhidata?.net}
                          </td>
                          <td className={hhidata?.pct != 0 ? (Number(hhidata?.pct?.replace("%", "")) > 0 ? "col_pchng val up" : Number(hhidata?.pct?.replace("%", "")) < 0 ? "col_pchng val down" : "col_pchng val") : (Number(hhidata?.pct) > 0 ? "col_pchng val up" : Number(hhidata?.pct) < 0 ? "col_pchng val down" : "col_pchng val")}>
                            {hhidata?.pct}
                          </td>
                          <td></td>
                        </tr>} */}
                      </tbody>
                    </table>
                    <div className="priceUpdateInfo">
                      <em>
                        {siteLang == "eng" ?
                          <span>
                            {languageText.futureClickRefreshEn}
                          </span>
                          :
                          <span>
                            {languageText.futureClickRefreshFirst}
                            <img className="refresh_index" style={{ cursor: "pointer", width: "22px" }} src="/img/bullet/refresh.png" onClick={refreshdata} />
                            {languageText.futureClickRefreshLast}
                          </span>
                        }
                        <br />{siteLang == "eng" ? languageText.futureTablePriceEn : languageText.futureTablePrice} <span className="ticktime">{updateDate}</span>{" "}
                        <br />
                        <div className="indicator-item">
                          <span className="icon-time indicator-item-icon" />
                          <span
                            className="ttip"
                            data-tip
                            data-for={"new-warrant-tooltip"}
                          >
                            HSI and HSTECH data provided by DB Power Online Limited [Disclaimer]
                          </span>
                        </div>
                        <ReactTooltip
                          id={"new-warrant-tooltip"}
                          delayHide={100}
                          place="left-start"
                          type="warning"
                          effect="solid"
                          clickable={true}
                        >
                          <div className="bg-tooltip">
                            <p className="tooltip-content">
                              DB Power Online Limited, “HKEX Information Services Limited, its holding companies and/or any subsidiaries of such holding companies”, China Investment Information Services Limited, third party information providers endeavor to ensure the accuracy and reliability of the information provided but do not guarantee its accuracy or reliability and accept no liability (whether in tort or contract or otherwise) for any loss or damage arising from any inaccuracies or omissions.
                              <br />
                              <br />
                              The BMP Service is aimed for investor reference. The BMP only provides basic market information for reference purposes and the investors should consider if they would need more detailed market information for reference or to facilitate their investment decision.
                              <img
                                style={{ width: "160px" }}
                                src="/img/home/dbpower.png"
                              ></img>
                            </p>
                          </div>
                        </ReactTooltip>
                      </em>
                    </div>
                  </div>
                </div>

                <div className="holder-newsbanner">
                  <div id="newsbanner" className="section">
                    <div className="page-header">
                      <h1 className="small">{siteLang == "eng" ? languageText.marketNewsTitleEn : languageText.marketNewsTitle}</h1>
                    </div>
                    <Slider {...settings} className="show-desk">
                      {dailyHsiBanner && <div className="gallery-item">
                        <a
                          className="homeimg-achor"
                          target="_blank"
                          href={`/marketnews/highlight?cat=hsi&id=${dailyHsiBanner.id}`}
                        >
                          <img
                            src={dailyHsiBanner.img}
                          />
                        </a>
                      </div>}
                      {dailyHighlightBanner && <div className="gallery-item">
                        <a
                          className="homeimg-achor"
                          target="_blank"
                          href={`/marketnews/highlight?cat=todayhighlight&id=${dailyHighlightBanner.id}`}
                        >
                          <img
                            src={dailyHighlightBanner.img}
                          />
                        </a>
                      </div>}
                      {dailySingleBanner && <div className="gallery-item">
                        <a
                          className="homeimg-achor"
                          target="_blank"
                          href={`/marketnews/highlight?cat=singlestock&id=${dailySingleBanner.id}`}
                        >
                          <img
                            src={dailySingleBanner.img}
                          />
                        </a>
                      </div>}
                    </Slider>
                  </div>
                </div>

                <div className="holder-topmover" style={{ position: "relative" }}>
                  <div id="topmover" className="section">
                    <div className="page-header">
                      <h1 className="small">Top movers</h1>
                    </div>
                    <div className="hotlist-row row" style={{ paddingRight: "15px", backgroundColor: "#DFDBCF", }}>
                      <div className="hotlist-right col-md-4 hidden-sm hidden-xs">
                        <div className="hotlist-col-h">
                          U.S. Index <span> <br /> <em style={{ fontSize: "16px" }}>
                            {siteLang == "eng" ? languageText.fifteenMinsEn : languageText.fifteenMins}
                          </em> </span>
                        </div>
                      </div>
                      <div className="hotlist-right col-md-4 hidden-sm hidden-xs">
                        <div className="hotlist-col-h">HK index</div>
                      </div>
                      <div className="hotlist-right col-md-4 hidden-sm hidden-xs">
                        <div className="hotlist-col-h">
                          HK stocks <span> <br /> <em style={{ fontSize: "16px" }}>
                            {siteLang == "eng" ? languageText.fifteenMinsEn : languageText.fifteenMins}
                          </em> </span>
                        </div>
                      </div>
                    </div>

                    <div style={{ marginBottom: '20px', maxHeight: '300px', overflowY: 'scroll', overflowX: 'hidden' }} className="hotlist-row row  hidden-sm hidden-xs">
                      <div className="row">
                        <div className="hotlist-right col-md-4 first usindex usindex_0">
                          <div data-index={0} className="usi hotlist-item index-item index-item-0">
                            <div className="hotlist-item-dataarea" onClick={hotlistItemDataArea}>
                              <div className="hotlist-item-nm">-</div>
                              <div className="hotlist-item-data">
                                <div className="hotlist-item-trend" />
                                <div className="hotlist-item-pc">-%</div>
                              </div>
                              <div className="hotlist-item-change">
                                <span className="hotlist-item-TRDPRC_1">-</span> (<span className="hotlist-item-nc">-</span>)
                              </div>
                            </div>
                            <div className="vmbtn-link"><a className="vabtn" target="_blank">View all<br />warrants</a></div>
                          </div>
                          <img src="/img/home/triangle_blue.png" style={{ textAlign: 'center', width: '20px', height: '20px', display: 'none', marginLeft: '35%' }} />
                        </div>
                        <div className="hotlist-right col-md-4  first hkindex hkindex_0">
                          <div data-index={0} className="hki hotlist-item index-item index-item-0">
                            <div className="hotlist-item-dataarea" onClick={hotlistItemDataArea}>
                              <div className="hotlist-item-nm">-</div>
                              <div className="hotlist-item-data">
                                <div className="hotlist-item-trend" />
                                <div className="hotlist-item-pc">-%</div>
                              </div>
                              <div className="hotlist-item-change">
                                <span className="hotlist-item-TRDPRC_1">-</span> (<span className="hotlist-item-nc">-</span>)
                              </div>
                            </div>
                            <div className="vmbtn-link"><a className="vabtn" target="_blank">View all<br />warrants</a></div>
                          </div>
                          <img src="/img/home/triangle_blue.png" style={{ textAlign: 'center', width: '20px', height: '20px', display: 'none', marginLeft: '35%' }} />
                        </div>
                        <div className="hotlist-right col-md-4 first hkstock hkstock_0">
                          <div data-index={0} className="hks hotlist-item index-item index-item-0">
                            <div className="hotlist-item-dataarea" onClick={hotlistItemDataArea}>
                              <div className="hotlist-item-nm">-</div>
                              <div className="hotlist-item-data">
                                <div className="hotlist-item-trend" />
                                <div className="hotlist-item-pc">-%</div>
                              </div>
                              <div className="hotlist-item-change">
                                <span className="hotlist-item-TRDPRC_1">-</span> (<span className="hotlist-item-nc">-</span>)
                              </div>
                            </div>
                            <div className="vmbtn-link"><a className="vabtn" target="_blank">{siteLang == "eng" ? "View all warrants" : "ค้นหา DW อ้างอิงหุ้นฮ่องกงทั้งหมด"}</a></div>
                          </div>
                          <img src="/img/home/triangle_blue.png" style={{ textAlign: 'center', width: '20px', height: '20px', display: 'block', marginLeft: '35%' }} />
                        </div>
                      </div>
                      <div className="row">
                        <div className="tw_area tw_area_0">
                          <span className="tw-name" />
                          <span className="delay15" style={{ display: 'none' }}><em style={{ fontSize: '12px' }}>Price on 15minute delay</em></span>
                          <div className="row tw_table" style={{ margin: '0px', padding: '0px' }}>
                            <div className="call_table col-xs-6">
                              <table className="tw_call_table" style={{ width: '100%' }}>
                                <tbody></tbody>
                              </table>
                            </div>
                            <div className="put_table col-xs-6">
                              <table className="tw_put_table" style={{ width: '100%' }}>
                                <tbody></tbody>
                              </table>
                            </div>
                            <p className="notw" style={{ display: 'none' }}>
                              No warrants available
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="hotlist-right col-md-4  usindex usindex_1">
                          <div data-index={1} className="usi hotlist-item index-item index-item-1">
                            <div className="hotlist-item-dataarea" onClick={hotlistItemDataArea}>
                              <div className="hotlist-item-nm">-</div>
                              <div className="hotlist-item-data">
                                <div className="hotlist-item-trend" />
                                <div className="hotlist-item-pc">-%</div>
                              </div>
                              <div className="hotlist-item-change">
                                <span className="hotlist-item-TRDPRC_1">-</span> (<span className="hotlist-item-nc">-</span>)
                              </div>
                            </div>
                            <div className="vmbtn-link"><a className="vabtn" target="_blank">View all<br />warrants</a></div>
                          </div>
                          <img src="/img/home/triangle_blue.png" style={{ textAlign: 'center', width: '20px', height: '20px', display: 'none', marginLeft: '35%' }} />
                        </div>
                        <div className="hotlist-right col-md-4 hkindex hkindex_1">
                          <div data-index={1} className="hki hotlist-item index-item index-item-1">
                            <div className="hotlist-item-dataarea" onClick={hotlistItemDataArea}>
                              <div className="hotlist-item-nm">-</div>
                              <div className="hotlist-item-data">
                                <div className="hotlist-item-trend" />
                                <div className="hotlist-item-pc">-%</div>
                              </div>
                              <div className="hotlist-item-change">
                                <span className="hotlist-item-TRDPRC_1">-</span> (<span className="hotlist-item-nc">-</span>)
                              </div>
                            </div>
                            <div className="vmbtn-link"><a className="vabtn" target="_blank">View all<br />warrants</a></div>
                          </div>
                          <img src="/img/home/triangle_blue.png" style={{ textAlign: 'center', width: '20px', height: '20px', display: 'none', marginLeft: '35%' }} />
                        </div>
                        <div className="hotlist-right col-md-4 hkstock hkstock_1">
                          <div data-index={1} className="hks hotlist-item index-item index-item-1">
                            <div className="hotlist-item-dataarea" onClick={hotlistItemDataArea}>
                              <div className="hotlist-item-nm">-</div>
                              <div className="hotlist-item-data">
                                <div className="hotlist-item-trend" />
                                <div className="hotlist-item-pc">-%</div>
                              </div>
                              <div className="hotlist-item-change">
                                <span className="hotlist-item-TRDPRC_1">-</span> (<span className="hotlist-item-nc">-</span>)
                              </div>
                            </div>
                            <div className="vmbtn-link"><a className="vabtn" target="_blank">{siteLang == "eng" ? "View all warrants" : "ค้นหา DW อ้างอิงหุ้นฮ่องกงทั้งหมด"}</a></div>
                          </div>
                          <img src="/img/home/triangle_blue.png" style={{ textAlign: 'center', width: '20px', height: '20px', display: 'none', marginLeft: '35%' }} />
                        </div>
                      </div>
                      <div className="row">
                        <div className="tw_area tw_area_1" style={{ display: 'none' }}>
                          <span className="tw-name" />
                          <span className="delay15" style={{ display: 'none' }}><em style={{ fontSize: '12px' }}>Price on 15minute delay</em></span>
                          <div className="row tw_table" style={{ margin: '0px', padding: '0px' }}>
                            <div className="call_table col-xs-6">
                              <table className="tw_call_table" style={{ width: '100%' }}>
                                <tbody></tbody>
                              </table>
                            </div>
                            <div className="put_table col-xs-6">
                              <table className="tw_put_table" style={{ width: '100%' }}>
                                <tbody></tbody>
                              </table>
                            </div>
                            <p className="notw" style={{ display: 'none' }}>
                              No warrants available
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="hotlist-right col-md-4 usindex usindex_2">
                          <div data-index={2} className="usi hotlist-item index-item index-item-2">
                            <div className="hotlist-item-dataarea" onClick={hotlistItemDataArea}>
                              <div className="hotlist-item-nm">-</div>
                              <div className="hotlist-item-data">
                                <div className="hotlist-item-trend" />
                                <div className="hotlist-item-pc">-%</div>
                              </div>
                              <div className="hotlist-item-change">
                                <span className="hotlist-item-TRDPRC_1">-</span> (<span className="hotlist-item-nc">-</span>)
                              </div>
                            </div>
                            <div className="vmbtn-link"><a className="vabtn" target="_blank">View all<br />warrants</a></div>
                          </div>
                          <img src="/img/home/triangle_blue.png" style={{ textAlign: 'center', width: '20px', height: '20px', display: 'none', marginLeft: '35%' }} />
                        </div>
                        <div className="hotlist-right col-md-4 hkindex hkindex_2">
                          <div data-index={2} className="hki hotlist-item index-item index-item-2">
                            <div className="hotlist-item-dataarea" onClick={hotlistItemDataArea}>
                              <div className="hotlist-item-nm">-</div>
                              <div className="hotlist-item-data">
                                <div className="hotlist-item-trend" />
                                <div className="hotlist-item-pc">-%</div>
                              </div>
                              <div className="hotlist-item-change">
                                <span className="hotlist-item-TRDPRC_1">-</span> (<span className="hotlist-item-nc">-</span>)
                              </div>
                            </div>
                            <div className="vmbtn-link"><a className="vabtn" target="_blank">View all<br />warrants</a></div>
                          </div>
                          <img src="/img/home/triangle_blue.png" style={{ textAlign: 'center', width: '20px', height: '20px', display: 'none', marginLeft: '35%' }} />
                        </div>
                        <div className="hotlist-right col-md-4 hkstock hkstock_2">
                          <div data-index={2} className="hks hotlist-item index-item index-item-2">
                            <div className="hotlist-item-dataarea" onClick={hotlistItemDataArea}>
                              <div className="hotlist-item-nm">-</div>
                              <div className="hotlist-item-data">
                                <div className="hotlist-item-trend" />
                                <div className="hotlist-item-pc">-%</div>
                              </div>
                              <div className="hotlist-item-change">
                                <span className="hotlist-item-TRDPRC_1">-</span> (<span className="hotlist-item-nc">-</span>)
                              </div>
                            </div>
                            <div className="vmbtn-link"><a className="vabtn" target="_blank">{siteLang == "eng" ? "View all warrants" : "ค้นหา DW อ้างอิงหุ้นฮ่องกงทั้งหมด"}</a></div>
                          </div>
                          <img src="/img/home/triangle_blue.png" style={{ textAlign: 'center', width: '20px', height: '20px', display: 'none', marginLeft: '35%' }} />
                        </div>
                      </div>
                      <div className="row">
                        <div className="tw_area tw_area_2" style={{ display: 'none' }}>
                          <span className="tw-name" />
                          <span className="delay15" style={{ display: 'none' }}><em style={{ fontSize: '12px' }}>Price on 15minute delay</em></span>
                          <div className="row tw_table" style={{ margin: '0px', padding: '0px' }}>
                            <div className="call_table col-xs-6">
                              <table className="tw_call_table" style={{ width: '100%' }}>
                                <tbody></tbody>
                              </table>
                            </div>
                            <div className="put_table col-xs-6">
                              <table className="tw_put_table" style={{ width: '100%' }}>
                                <tbody></tbody>
                              </table>
                            </div>
                            <p className="notw" style={{ display: 'none' }}>
                              No warrants available
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="hotlist-right col-md-4  usindex usindex_3">
                          <div data-index={3} className="usi hotlist-item index-item index-item-3">
                            <div className="hotlist-item-dataarea" onClick={hotlistItemDataArea}>
                              <div className="hotlist-item-nm">-</div>
                              <div className="hotlist-item-data">
                                <div className="hotlist-item-trend" />
                                <div className="hotlist-item-pc">-%</div>
                              </div>
                              <div className="hotlist-item-change">
                                <span className="hotlist-item-TRDPRC_1">-</span> (<span className="hotlist-item-nc">-</span>)
                              </div>
                            </div>
                            <div className="vmbtn-link"><a className="vabtn" target="_blank">View all<br />warrants</a></div>
                          </div>
                          <img src="/img/home/triangle_blue.png" style={{ textAlign: 'center', width: '20px', height: '20px', display: 'none', marginLeft: '35%' }} />
                        </div>
                        <div className="hotlist-right col-md-4 hkindex hkindex_3">
                          <div data-index={3} className="hki hotlist-item index-item index-item-3">
                            <div className="hotlist-item-dataarea" onClick={hotlistItemDataArea}>
                              <div className="hotlist-item-nm">-</div>
                              <div className="hotlist-item-data">
                                <div className="hotlist-item-trend" />
                                <div className="hotlist-item-pc">-%</div>
                              </div>
                              <div className="hotlist-item-change">
                                <span className="hotlist-item-TRDPRC_1">-</span> (<span className="hotlist-item-nc">-</span>)
                              </div>
                            </div>
                            <div className="vmbtn-link"><a className="vabtn" target="_blank">View all<br />warrants</a></div>
                          </div>
                          <img src="/img/home/triangle_blue.png" style={{ textAlign: 'center', width: '20px', height: '20px', display: 'none', marginLeft: '35%' }} />
                        </div>
                        <div className="hotlist-right col-md-4 hkstock hkstock_3">
                          <div data-index={3} className="hks hotlist-item index-item index-item-3">
                            <div className="hotlist-item-dataarea" onClick={hotlistItemDataArea}>
                              <div className="hotlist-item-nm">-</div>
                              <div className="hotlist-item-data">
                                <div className="hotlist-item-trend" />
                                <div className="hotlist-item-pc">-%</div>
                              </div>
                              <div className="hotlist-item-change">
                                <span className="hotlist-item-TRDPRC_1">-</span> (<span className="hotlist-item-nc">-</span>)
                              </div>
                            </div>
                            <div className="vmbtn-link"><a className="vabtn" target="_blank">{siteLang == "eng" ? "View all warrants" : "ค้นหา DW อ้างอิงหุ้นฮ่องกงทั้งหมด"}</a></div>
                          </div>
                          <img src="/img/home/triangle_blue.png" style={{ textAlign: 'center', width: '20px', height: '20px', display: 'none', marginLeft: '35%' }} />
                        </div>
                      </div>
                      <div className="row">
                        <div className="tw_area tw_area_3" style={{ display: 'none' }}>
                          <span className="tw-name" /><span className="delay15" style={{ display: 'none' }}><em style={{ fontSize: '12px' }}>Price on 15minute delay</em></span>
                          <div className="row tw_table" style={{ margin: '0px', padding: '0px' }}>
                            <div className="call_table col-xs-6">
                              <table className="tw_call_table" style={{ width: '100%' }}>
                                <tbody></tbody>
                              </table>
                            </div>
                            <div className="put_table col-xs-6">
                              <table className="tw_put_table" style={{ width: '100%' }}>
                                <tbody></tbody>
                              </table>
                            </div>
                            <p className="notw" style={{ display: 'none' }}>
                              No warrants available
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="visible-xs visible-sm">
                      <div className="hotlist-row row" style={{ paddingRight: '15px', backgroundColor: '#DFDBCF' }}>
                        <div className="hotlist-right col-xs-4 hottab hottabusi" onClick={hottabusiFunc}>
                          <div className="hotlist-col-h">U.S. Index</div>
                        </div>
                        <div className="hotlist-right col-xs-4 hottab hottabhki" onClick={hottabhkiFunc}>
                          <div className="hotlist-col-h">HK index</div>
                        </div>
                        <div className="hotlist-right col-xs-4 hottab hottabhks active" onClick={hottabhksFunc}>
                          <div className="hotlist-col-h">HK stocks</div>
                        </div>
                      </div>
                      <div className="hotlist-row row ulist usilist ">
                        <div className="hotlist-right col-md-4">
                          <div className="hotlist-row first usindex">
                            <div className="hotlist-col usindex usindex_0">
                              <div data-index={0} className="usi hotlist-item index-item index-item-0">
                                <div className="hotlist-item-dataarea" onClick={() => hotlistItemDataArea("mobile")}>
                                  <div className="hotlist-item-nm">-</div>
                                  <div className="hotlist-item-data">
                                    <div className="hotlist-item-trend" />
                                    <div className="hotlist-item-pc">-%</div>
                                  </div>
                                  <div className="hotlist-item-change">
                                    <span className="hotlist-item-TRDPRC_1">-</span> (<span className="hotlist-item-nc">-</span>)
                                  </div>
                                </div>
                                <div className="vmbtn-link"><a className="vabtn" target="_blank">View all<br />warrants</a></div>
                              </div>
                            </div>
                            <div className="hotlist-col">
                              <div data-index={1} className="usi hotlist-item index-item index-item-1">
                                <div className="hotlist-item-dataarea" onClick={() => hotlistItemDataArea("mobile")}>
                                  <div className="hotlist-item-nm">-</div>
                                  <div className="hotlist-item-data">
                                    <div className="hotlist-item-trend" />
                                    <div className="hotlist-item-pc">-%</div>
                                  </div>
                                  <div className="hotlist-item-change">
                                    <span className="hotlist-item-TRDPRC_1">-</span> (<span className="hotlist-item-nc">-</span>)
                                  </div>
                                </div>
                                <div className="vmbtn-link"><a className="vabtn" target="_blank">View all<br />warrants</a></div>
                              </div>
                            </div>
                            <div className="hotlist-col">
                              <div data-index={2} className="usi hotlist-item index-item index-item-2">
                                <div className="hotlist-item-dataarea" onClick={() => hotlistItemDataArea("mobile")}>
                                  <div className="hotlist-item-nm">-</div>
                                  <div className="hotlist-item-data">
                                    <div className="hotlist-item-trend" />
                                    <div className="hotlist-item-pc">-%</div>
                                  </div>
                                  <div className="hotlist-item-change">
                                    <span className="hotlist-item-TRDPRC_1">-</span> (<span className="hotlist-item-nc">-</span>)
                                  </div>
                                </div>
                                <div className="vmbtn-link"><a className="vabtn" target="_blank">View all<br />warrants</a></div>
                              </div>
                            </div>
                            <div className="hotlist-col">
                              <div data-index={3} className="usi hotlist-item index-item index-item-3">
                                <div className="hotlist-item-dataarea" onClick={() => hotlistItemDataArea("mobile")}>
                                  <div className="hotlist-item-nm">-</div>
                                  <div className="hotlist-item-data">
                                    <div className="hotlist-item-trend" />
                                    <div className="hotlist-item-pc">-%</div>
                                  </div>
                                  <div className="hotlist-item-change">
                                    <span className="hotlist-item-TRDPRC_1">-</span> (<span className="hotlist-item-nc">-</span>)
                                  </div>
                                </div>
                                <div className="vmbtn-link"><a className="vabtn" target="_blank">View all<br />warrants</a></div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="hotlist-row row ulist hkilist">
                        <div className="hotlist-right col-md-4">
                          <div className="hotlist-row first hkindex">
                            <div className="hotlist-col hkindex hkindex_0">
                              <div data-index={0} className="hki hotlist-item index-item index-item-0">
                                <div className="hotlist-item-dataarea" onClick={() => hotlistItemDataArea("mobile")}>
                                  <div className="hotlist-item-nm">-</div>
                                  <div className="hotlist-item-data">
                                    <div className="hotlist-item-trend" />
                                    <div className="hotlist-item-pc">-%</div>
                                  </div>
                                  <div className="hotlist-item-change">
                                    <span className="hotlist-item-TRDPRC_1">-</span> (<span className="hotlist-item-nc">-</span>)
                                  </div>
                                </div>
                                <div className="vmbtn-link"><a className="vabtn" target="_blank">View all<br />warrants</a></div>
                              </div>
                            </div>
                            <div className="hotlist-col hkindex hkindex_1">
                              <div data-index={1} className="hki hotlist-item index-item index-item-1">
                                <div className="hotlist-item-dataarea" onClick={() => hotlistItemDataArea("mobile")}>
                                  <div className="hotlist-item-nm">-</div>
                                  <div className="hotlist-item-data">
                                    <div className="hotlist-item-trend" />
                                    <div className="hotlist-item-pc">-%</div>
                                  </div>
                                  <div className="hotlist-item-change">
                                    <span className="hotlist-item-TRDPRC_1">-</span> (<span className="hotlist-item-nc">-</span>)
                                  </div>
                                </div>
                                <div className="vmbtn-link"><a className="vabtn" target="_blank">View all<br />warrants</a></div>
                              </div>
                            </div>
                            <div className="hotlist-col hkindex hkindex_2">
                              <div data-index={2} className="hki hotlist-item index-item index-item-2">
                                <div className="hotlist-item-dataarea" onClick={() => hotlistItemDataArea("mobile")}>
                                  <div className="hotlist-item-nm">-</div>
                                  <div className="hotlist-item-data">
                                    <div className="hotlist-item-trend" />
                                    <div className="hotlist-item-pc">-%</div>
                                  </div>
                                  <div className="hotlist-item-change">
                                    <span className="hotlist-item-TRDPRC_1">-</span> (<span className="hotlist-item-nc">-</span>)
                                  </div>
                                </div>
                                <div className="vmbtn-link"><a className="vabtn" target="_blank">View all<br />warrants</a></div>
                              </div>
                            </div>
                            <div className="hotlist-col hkindex hkindex_3">
                              <div data-index={3} className="hki hotlist-item index-item index-item-3">
                                <div className="hotlist-item-dataarea" onClick={() => hotlistItemDataArea("mobile")}>
                                  <div className="hotlist-item-nm">-</div>
                                  <div className="hotlist-item-data">
                                    <div className="hotlist-item-trend" />
                                    <div className="hotlist-item-pc">-%</div>
                                  </div>
                                  <div className="hotlist-item-change">
                                    <span className="hotlist-item-TRDPRC_1">-</span> (<span className="hotlist-item-nc">-</span>)
                                  </div>
                                </div>
                                <div className="vmbtn-link"><a className="vabtn" target="_blank">View all<br />warrants</a></div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="hotlist-row row ulist hkslist active">
                        <div className="hotlist-right col-md-4">
                          <div className="hotlist-row first hkstock">
                            <div className="hotlist-col hkstock hkstock_0">
                              <div data-index={0} className="hks hotlist-item index-item index-item-0">
                                <div className="hotlist-item-dataarea" onClick={() => hotlistItemDataArea("mobile")}>
                                  <div className="hotlist-item-nm">-</div>
                                  <div className="hotlist-item-data">
                                    <div className="hotlist-item-trend" />
                                    <div className="hotlist-item-pc">-%</div>
                                  </div>
                                  <div className="hotlist-item-change">
                                    <span className="hotlist-item-TRDPRC_1">-</span> (<span className="hotlist-item-nc">-</span>)
                                  </div>
                                </div>
                                <div className="vmbtn-link"><a className="vabtn" target="_blank">{siteLang == "eng" ? "View all warrants" : "ค้นหา DW อ้างอิงหุ้นฮ่องกงทั้งหมด"}</a></div>
                              </div>
                            </div>
                            <div className="hotlist-col hkstock hkstock_1">
                              <div data-index={1} className="hks hotlist-item index-item index-item-1">
                                <div className="hotlist-item-dataarea" onClick={() => hotlistItemDataArea("mobile")}>
                                  <div className="hotlist-item-nm">-</div>
                                  <div className="hotlist-item-data">
                                    <div className="hotlist-item-trend" />
                                    <div className="hotlist-item-pc">-%</div>
                                  </div>
                                  <div className="hotlist-item-change">
                                    <span className="hotlist-item-TRDPRC_1">-</span> (<span className="hotlist-item-nc">-</span>)
                                  </div>
                                </div>
                                <div className="vmbtn-link"><a className="vabtn" target="_blank">{siteLang == "eng" ? "View all warrants" : "ค้นหา DW อ้างอิงหุ้นฮ่องกงทั้งหมด"}</a></div>
                              </div>
                            </div>
                            <div className="hotlist-col hkstock hkstock_2">
                              <div data-index={2} className="hks hotlist-item index-item index-item-2">
                                <div className="hotlist-item-dataarea" onClick={() => hotlistItemDataArea("mobile")}>
                                  <div className="hotlist-item-nm">-</div>
                                  <div className="hotlist-item-data">
                                    <div className="hotlist-item-trend" />
                                    <div className="hotlist-item-pc">-%</div>
                                  </div>
                                  <div className="hotlist-item-change">
                                    <span className="hotlist-item-TRDPRC_1">-</span> (<span className="hotlist-item-nc">-</span>)
                                  </div>
                                </div>
                                <div className="vmbtn-link"><a className="vabtn" target="_blank">{siteLang == "eng" ? "View all warrants" : "ค้นหา DW อ้างอิงหุ้นฮ่องกงทั้งหมด"}</a></div>
                              </div>
                            </div>
                            <div className="hotlist-col hkstock hkstock_3">
                              <div data-index={3} className="hks hotlist-item index-item index-item-3">
                                <div className="hotlist-item-dataarea" onClick={() => hotlistItemDataArea("mobile")}>
                                  <div className="hotlist-item-nm">-</div>
                                  <div className="hotlist-item-data">
                                    <div className="hotlist-item-trend" />
                                    <div className="hotlist-item-pc">-%</div>
                                  </div>
                                  <div className="hotlist-item-change">
                                    <span className="hotlist-item-TRDPRC_1">-</span> (<span className="hotlist-item-nc">-</span>)
                                  </div>
                                </div>
                                <div className="vmbtn-link"><a className="vabtn" target="_blank">{siteLang == "eng" ? "View all warrants" : "ค้นหา DW อ้างอิงหุ้นฮ่องกงทั้งหมด"}</a></div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="tw_area">
                        <span className="tw-name" /><span className="delay15" style={{ display: 'none' }}><em style={{ fontSize: '12px' }}>Price on 15minute delay</em></span>
                        <div className="row tw_table" style={{ margin: '0px', padding: '0px' }}>
                          <div className="call_table col-xs-6">
                            <table className="tw_call_table" style={{ width: '100%' }}>
                              <tbody>
                                <tr>
                                  <td />
                                </tr>
                              </tbody>
                            </table>
                            <table className="tmp_table" style={{ display: 'none' }}>
                              <tbody>
                                <tr className="call_tmp">
                                  <td />
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          <div className="put_table col-xs-6">
                            <table className="tw_put_table" style={{ width: '100%' }}>
                              <tbody>
                                <tr>
                                  <td />
                                </tr>
                              </tbody>
                            </table>
                            <table className="tmp_table" style={{ display: 'none' }}>
                            </table>
                          </div>
                          <p className="notw" style={{ display: 'none' }}>
                            No warrants available
                          </p>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>

                <div className="holder-dw28 hidden-xs">
                  <div id="dw28" className="section">
                    <div className="page-header">
                      <h1 className="small">{siteLang == "eng" ? languageText.dw28UpdateEn : languageText.dw28Update}</h1>
                    </div>
                    <table style={{ width: "100%" }}>
                      <tbody>
                        <tr>
                          <td className="tipscontent">
                            <div className="newstbl-content">
                              {dw28 && dw28.map((data, index) => (
                                <div className="newstbl-row rowtmpl row" key={index}>
                                  <div className="newstbl-col-left col-xs-3">
                                    {moment(new Date(data.publish_date)).format("DD MMM YY")}
                                  </div>
                                  <div
                                    className="newstbl-col-right col-xs-9"
                                    onClick={() => openModalPopup(data.id)}
                                  >
                                    {siteLang == "eng" ? data.en_title : data.thai_title}
                                  </div>
                                </div>
                              ))}
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>

                <div className="holder-dwtips hidden-xs">
                  <div id="dwtips" className="section">
                    <div className="page-header">
                      <h1 className="small">{siteLang == "eng" ? languageText.dwtipsTitleEn : languageText.dwtipsTitle}</h1>
                    </div>
                    <p>{siteLang == "eng" ? languageText.dwtipsParaEn : languageText.dwtipsPara}</p>
                    <table style={{ width: "100%" }}>
                      <tbody>
                        {tipsImg.length > 0 && <tr>
                          <td className="leftTipsImg">
                            <Link to={{ pathname: tipsImg && tipsImg[0] && tipsImg[0].img_link }} target="_top">
                              <img src={tipsImg && tipsImg[0] && tipsImg[0].img_id} alt="DW Tips" style={{ width: "100%" }} />
                            </Link>
                          </td>
                        </tr>}
                        <tr>
                          <td className="tipscontent">
                            <div className="newstbl-content">
                              {dwTips && dwTips.map((data, index) => (
                                <div className="newstbl-row rowtmpl" key={index}>
                                  <div className="newstbl-col-right">
                                    <Link to={`/education/individualtip?id=${data.id}`} className="newstbl-col-dt" target="_blank"
                                      dangerouslySetInnerHTML={{ __html: siteLang == "eng" ? data.en_title : data.thai_title }}></Link>
                                    <Link to={`/education/individualtip?id=${data.id}`} target="_blank" className="newstbl-col-rm">
                                      <img src="/img/bullet/qlink-achor.png" alt="Arrow Icon" />
                                    </Link>
                                  </div>
                                </div>
                              ))}
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <div className="section-btn-wapper">
                      <Link to="/education/dwtips" style={{ minWidth: "190px" }} type="button" className="btn btn-primary">
                        {siteLang == "eng" ? languageText.dwtipsButtonEn : languageText.dwtipsButton}
                      </Link>
                    </div>
                  </div>
                </div>

              </div>

              <div className="col-md-6 col-sm-6">

                <div className="holder-indexfuture hidden-xs">
                  <div id="indexfuture" className="section">
                    <div className="page-header">
                      <h1 className="small">
                        {siteLang == "eng" ? languageText.liveIndexTitleEn : languageText.liveIndexTitle}
                      </h1>
                    </div>
                    <table className="table table-striped table-noHeaderborder">
                      <thead>
                        <tr>
                          <th>{siteLang == "eng" ? languageText.futureTableIndexEn : languageText.futureTableIndex}</th>
                          <th>{siteLang == "eng" ? languageText.futureTableContractEn : languageText.futureTableContract} </th>
                          <th>{siteLang == "eng" ? languageText.futureTableLastEn : languageText.futureTableLast}</th>
                          <th>{siteLang == "eng" ? languageText.futureTableChangeEn : languageText.futureTableChange} </th>
                          <th>{siteLang == "eng" ? languageText.futureTableChangePerEn : languageText.futureTableChangePer}%</th>
                          <th />
                        </tr>
                      </thead>
                      <tbody>
                        {hsidata && <tr className="row_HSI">
                          <td>
                            <Link to="/tools/dwranking/HSI">HSI*</Link>
                          </td>
                          <td className="col_month">{hsidata?.month}</td>
                          <td className="col_last">{new Intl.NumberFormat('en-US').format(hsidata?.last)}</td>
                          <td className={Number(hsidata?.chng) > 0 ? "col_chng val up" : Number(hsidata?.chng) < 0 ? "col_chng val down" : "col_chng val"}>
                            {Number(hsidata?.chng) > 0 ? "+" + hsidata?.chng : hsidata?.chng}
                          </td>
                          <td className={hsidata?.pchng != 0 ? (Number(hsidata?.pchng?.replace("%", "")) > 0 ? "col_pchng val up" : Number(hsidata?.pchng?.replace("%", "")) < 0 ? "col_pchng val down" : "col_pchng val") : (Number(hsidata?.pchng) > 0 ? "col_pchng val up" : Number(hsidata?.pchng) < 0 ? "col_pchng val down" : "col_pchng val")}>
                            {hsidata?.pchng != 0 ? (Number(hsidata?.pchng?.replace("%", "")) > 0 ? '+' + hsidata?.pchng?.replace("%", "") : hsidata?.pchng?.replace("%", "")) : (Number(hsidata?.pchng) > 0 ? '+' + hsidata?.pchng : hsidata?.pchng)}
                          </td>
                          <td rowSpan={2} style={{ verticalAlign: 'middle' }}>
                            <img onClick={refreshdata} className="refresh_index" style={{ cursor: 'pointer', width: '22px' }} src="/img/bullet/refresh.png" alt="refresh" />
                          </td>
                        </tr>}
                        {htidata && <tr className="row_HTI">
                          <td>
                            <Link to="/tools/dwranking/HSTECH">HSTECH*</Link>
                          </td>
                          <td className="col_month">{htidata?.month}</td>
                          <td className="col_last">{new Intl.NumberFormat('en-US').format(htidata?.last)}</td>
                          <td className={Number(htidata?.chng) > 0 ? "col_chng val up" : Number(htidata?.chng) < 0 ? "col_chng val down" : "col_chng val"}>
                            {Number(htidata?.chng) > 0 ? "+" + htidata?.chng : htidata?.chng}
                          </td>
                          <td className={htidata?.pchng != 0 ? (Number(htidata?.pchng?.replace("%", "")) > 0 ? "col_pchng val up" : Number(htidata?.pchng?.replace("%", "")) < 0 ? "col_pchng val down" : "col_pchng val") : (Number(htidata?.pchng) > 0 ? "col_pchng val up" : Number(htidata?.pchng) < 0 ? "col_pchng val down" : "col_pchng val")}>
                            {htidata?.pchng != 0 ? (Number(htidata?.pchng?.replace("%", "")) > 0 ? '+' + htidata?.pchng?.replace("%", "") : htidata?.pchng?.replace("%", "")) : (Number(htidata?.pchng) > 0 ? '+' + htidata?.pchng : htidata?.pchng)}
                          </td>
                        </tr>}
                        {/* ${prevNetChng>hhidata?.net?'downtick':'uptick'} */}
                        {/* {hhidata && <tr className={`row_HHI`}>
                          <td>
                            <Link to="/tools/dwranking/HSCEI">NIKKEI 225</Link>
                          </td>
                          <td className="col_month">{hhidata?.expiryMonth}</td>
                          <td className="col_last">{hhidata?.bid}</td>
                          <td className={Number(hhidata?.net) > 0 ? "col_chng val up" : Number(hhidata?.net) < 0 ? "col_chng val down" : "col_chng val"}>
                            {hhidata?.net}
                          </td>
                          <td className={hhidata?.pct != 0 ? (Number(hhidata?.pct?.replace("%", "")) > 0 ? "col_pchng val up" : Number(hhidata?.pct?.replace("%", "")) < 0 ? "col_pchng val down" : "col_pchng val") : (Number(hhidata?.pct) > 0 ? "col_pchng val up" : Number(hhidata?.pct) < 0 ? "col_pchng val down" : "col_pchng val")}>
                            {hhidata?.pct}
                          </td>
                          <td></td>
                        </tr>} */}
                      </tbody>
                    </table>
                    <div className="priceUpdateInfo">
                      <em>
                        {siteLang == "eng" ?
                          <span>
                            {languageText.futureClickRefreshEn}
                          </span>
                          :
                          <span>
                            {languageText.futureClickRefreshFirst}
                            <img className="refresh_index" style={{ cursor: "pointer", width: "22px" }} src="/img/bullet/refresh.png" onClick={refreshdata} />
                            {languageText.futureClickRefreshLast}
                          </span>
                        }
                        <br />{siteLang == "eng" ? languageText.futureTablePriceEn : languageText.futureTablePrice} <span className="ticktime">{updateDate}</span>{" "}
                        <br />
                        <div className="indicator-item">
                          <span className="icon-time indicator-item-icon" />
                          <span
                            className="ttip"
                            data-tip
                            data-for={"new-warrant-tooltip"}
                          >
                            HSI and HSTECH data provided by DB Power Online Limited [Disclaimer]
                          </span>
                        </div>
                        <ReactTooltip
                          id={"new-warrant-tooltip"}
                          delayHide={100}
                          place="left-start"
                          type="warning"
                          effect="solid"
                          clickable={true}
                        >
                          <div className="bg-tooltip">
                            <p className="tooltip-content">
                              DB Power Online Limited, “HKEX Information Services Limited, its holding companies and/or any subsidiaries of such holding companies”, China Investment Information Services Limited, third party information providers endeavor to ensure the accuracy and reliability of the information provided but do not guarantee its accuracy or reliability and accept no liability (whether in tort or contract or otherwise) for any loss or damage arising from any inaccuracies or omissions.
                              <br />
                              <br />
                              The BMP Service is aimed for investor reference. The BMP only provides basic market information for reference purposes and the investors should consider if they would need more detailed market information for reference or to facilitate their investment decision.
                              <img
                                style={{ width: "160px" }}
                                src="/img/home/dbpower.png"
                              ></img>
                            </p>
                          </div>
                        </ReactTooltip>
                      </em>
                    </div>
                  </div>
                </div>

                <div className="holder-qlink-dwsearch hidden-xs">
                  <div id="qlink-dwsearch" className="section" style={{ backgroundColor: "#000" }}>
                    <div className="page-header">
                      <h1 className="small" style={{ color: "#FECC0A" }}>
                        {siteLang == "eng" ? languageText.dwSearchTitleEn : languageText.dwSearchTitle}
                      </h1>
                    </div>
                    <div className="row">
                      <div className="col-md-6 col-md-12">
                        <Link to="/tools/dwsearch/Allforeignindices">
                          {siteLang == "eng" ? (
                            <img
                              className="search_btn"
                              src="/img/home/search_en1.png"
                            />
                          ) : (
                            <img
                              className="search_btn"
                              src="/img/home/search_th1.png"
                            />
                          )}
                        </Link>
                      </div>
                      <div className="col-md-6 col-md-12">
                        <Link to="/tools/dwsearch/HKindex">
                          {siteLang == "eng" ? (
                            <img
                              className="search_btn"
                              src="/img/home/search_en2.png"
                            />
                          ) : (
                            <img
                              className="search_btn"
                              src="/img/home/search_th2.png"
                            />
                          )}
                        </Link>
                      </div>
                      <div className="col-md-6 col-md-12">
                        <Link to="/tools/dwsearch/HKSS">
                          {siteLang == "eng" ? (
                            <img
                              className="search_btn"
                              src="/img/home/search_en3.png"
                            />
                          ) : (
                            <img
                              className="search_btn"
                              src="/img/home/search_th3.png"
                            />
                          )}
                        </Link>
                      </div>
                      <div className="col-md-6 col-md-12">
                        <Link to="/tools/dwsearch/USindex">
                          {siteLang == "eng" ? (
                            <img
                              className="search_btn"
                              src="/img/home/search_en4.png"
                            />
                          ) : (
                            <img
                              className="search_btn"
                              src="/img/home/search_th4.png"
                            />
                          )}
                        </Link>
                      </div>
                      <div className="col-md-6 col-md-12">
                        <Link to="/tools/dwsearch/ThaiSS">
                          {siteLang == "eng" ? (
                            <img
                              className="search_btn"
                              src="/img/home/search_en5.png"
                            />
                          ) : (
                            <img
                              className="search_btn"
                              src="/img/home/search_th5.png"
                            />
                          )}
                        </Link>
                      </div>
                      <div className="col-md-6 col-md-12">
                        <Link to="/tools/dwsearch/Thaiindex">
                          {siteLang == "eng" ? (
                            <img
                              className="search_btn"
                              src="/img/home/search_en6.png"
                            />
                          ) : (
                            <img
                              className="search_btn"
                              src="/img/home/search_th6.png"
                            />
                          )}
                        </Link>
                      </div>
                    </div>
                    <div className="section-btn-wapper">
                      <Link to="/tools/dwsearch" type="button" className="btn btn-primary">
                        {siteLang == "eng" ? languageText.dwSearchBtnEn : languageText.dwSearchBtn}
                      </Link>
                    </div>
                  </div>
                </div>

                <div className="holder-lmatrix visible-xs">
                  <div id="lmatrix" className="section">
                    <div className="page-header">
                      <h1 className="small">{siteLang == "eng" ? languageText.liveMatrixTitleEn : languageText.liveMatrixTitle}</h1>
                    </div>
                    <p className="section-p">
                      {siteLang == "eng" ? languageText.liveMatrixParaEn : languageText.liveMatrixPara}
                    </p>
                    <div id="dropdown-livematrix">
                      <Select
                        onChange={(e) => redirectToLiveMatrix(e)}
                        placeholder={siteLang == "eng" ? "Select DW symbol" : "เลือกชื่อย่อ DW"}
                        className="Warrent_dropdown"
                        options={matrixSymbols && matrixSymbols.map((d) => {
                          return { value: d.security_code, label: d.security_code }
                        })}
                        styles={colourStyles}
                      />
                    </div>
                    <div id="ticker" className="ricbar bgcolor-03">
                      {matrixTicker}
                    </div>
                    <div id="lmtablecontainer">
                      <table id="today_table" className="table lbtable">
                        <thead>
                          <tr>
                            <th width="50%">
                              <span className="th-left">
                                {siteLang == "eng" ? languageText.matrixTableUnderlyingEn : languageText.matrixTableUnderlying}
                              </span>
                            </th>
                            <th width="50%">
                              <a
                                style={{ display: "none" }}
                                className="icon-refresh color-hyperlink pull-right"
                              />
                              {siteLang == "eng" ? languageText.matrixTableDwEn : languageText.matrixTableDw}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {/* <tr className="bgcolor-08">
                            <td>19566.00</td>
                            <td className="right-b01">1.40</td>
                          </tr> */}
                          <tr>
                            <td colSpan="6" className="text-content">
                              {siteLang == "eng" ? languageText.noLiveMatrixEn : languageText.noLiveMatrix}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div className="section-btn-wapper">
                      <Link
                        to={"/tools/livematrix/"+matrixTicker}
                        style={{ minWidth: "190px" }}
                        type="button"
                        className="btn btn-primary"
                      >
                        {siteLang == "eng" ? languageText.matrixTableButtonEn : languageText.matrixTableButton}
                      </Link>
                    </div>
                  </div>
                </div>

                <div className="holder-lastView">
                  <div id="lastView" className="section">
                    <div className="page-header">
                      <h1 className="small">{siteLang == "eng" ? languageText.lastViewTitleEn : languageText.lastViewTitle}</h1>
                    </div>
                    <table className="table table-striped table-closed">
                      <thead>
                        <tr>
                          <th>DW Symbol</th>
                          <th>DW bid</th>
                          <th>Eff. gearing</th>
                          <th>Sensitivity</th>
                        </tr>
                      </thead>
                      <tbody>
                        {lastViewVal?.length > 0 && lastViewVal.map((data, index) => (
                          <tr className="lastView_rowtmpl" key={index}>
                            <td className="col_tkr" style={{ fontWeight: "bold" }}>
                              <Link target="_blank" to={`/tools/livematrix/${data?.ticker}`}>
                                {data?.ticker ? data?.ticker : "-"}
                              </Link>
                            </td>
                            <td className="col_bd" style={{ textAlign: "center" }}>
                              {data?.BID ? data?.BID : "-"}
                            </td>
                            <td className="col_eg" style={{ textAlign: "center" }}>
                              {data?.effective_gearing ? data?.effective_gearing : "-"}
                            </td>
                            <td className="col_sen" style={{ textAlign: "center" }}>
                              {data?.sensitivity ? data?.sensitivity : "-"}
                            </td>
                          </tr>
                        ))}
                        {lastViewVal?.length == 0 && <tr className="emptymsg"><td colSpan="4">No last view</td></tr>}
                      </tbody>
                    </table>
                  </div>
                </div>

                <div className="row hidden-sm hidden-xs">
                  <div className="holder-facebook-iframe col-xs-6">
                    <div
                      id="facebook-iframe"
                      className="section"
                      style={{ padding: 0 }}
                    >
                      <iframe
                        src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Fmacquariedw28%2F&tabs=timeline&width=280&height=375&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId"
                        width={280}
                        height={370}
                        style={{ border: "none", overflow: "hidden" }}
                        scrolling="no"
                        frameBorder={0}
                        allowtransparency="true"
                        allow="encrypted-media"
                        data-gtm-yt-inspected-5="true"
                      />
                    </div>
                  </div>
                  <div className="holder-line col-xs-6">
                    <div
                      id="line"
                      className="section"
                      style={{ padding: "40px 20px" }}
                    >
                      <div>
                        <img src="/img/home/line-icon.jpg" /> Line : @thaidw
                      </div>
                      <div style={{ padding: "4px 0px" }} className="thai_text">
                        อัพเดตข่าวสดใหม่
                      </div>
                      <div>
                        <img src="/img/home/lineQR.jpg" />
                      </div>
                      <div style={{ padding: "4px 0px" }} className="thai_text">
                        สแกนเลย!
                      </div>
                    </div>
                  </div>
                </div>

                <div id="video-dwtool-sm" className="holder-advertisment holder-empty hidden-xs">
                  <AdvertiseMentSidebar />
                </div>

                <div className="holder-dw28 visible-xs">
                  <div id="dw28" className="section">
                    <div className="page-header">
                      <h1 className="small">{siteLang == "eng" ? languageText.dw28UpdateEn : languageText.dw28Update}</h1>
                    </div>
                    <table style={{ width: "100%" }}>
                      <tbody>
                        <tr>
                          <td className="tipscontent">
                            <div className="newstbl-content">
                              {dw28 && dw28.map((data, index) => (
                                <div className="newstbl-row rowtmpl row" key={index}>
                                  <div className="newstbl-col-left col-xs-3">
                                    {moment(new Date(data.publish_date)).format("DD MMM YY")}
                                  </div>
                                  <div
                                    className="newstbl-col-right col-xs-9"
                                    onClick={() => openModalPopup(data.id)}
                                  >
                                    {siteLang == "eng" ? data.en_title : data.thai_title}
                                  </div>
                                </div>
                              ))}
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>

              </div>

            </div>
          </div>
        </div>
        {popUpClose && <div id="ppboxHTML3" className="overlay" style={{ display: 'block' }}>
          <div className="overlay-inner">
            <OutsideClickHandler
              onOutsideClick={closeModalPopup}>
              <div style={{ position: 'relative' }}>
                <img className="btn-close" src="/img/bullet/icon-close-white.png" onClick={closeModalPopup} />
                <div className="popupbox-content">
                  {popUpImg != "" && <img className="ppimg" src={popUpImg} alt="popUpImg" />}
                </div>
              </div>
            </OutsideClickHandler>
          </div>
        </div>}
      </div>
    </div>
  );
};

export default WarrantTool;